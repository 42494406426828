.cardHeader {
    padding: 15px;
    border-bottom: 1px solid #eeeeee;
    height: 50px;
  }
  .cardHeading {
    font-weight: 600;
    font-size: 16px;
    padding-left: 10%;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5);
  }
  .css-1sb8464-MuiResponsiveChart-container>svg{
    width: 100%;
    height: 84% !important;
  }
  .greenBlNum{
    color: #329CAA;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .css-1eg20ca-MuiResponsiveChart-container>svg{
    width: 250% !important;
  }
  .css-10vg0a6-MuiResponsiveChart-container>svg{
    width: 150%;
  }
.redNum{
  color: #B40000;
font-size: 12px;
font-weight: 400;
}

.folloo{
  color: var(--Black, #000);
font-size: 12px;
font-weight: 400;
}
.losty{
  color: #2E4958;
text-align: center;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.avgg{
  color: #191D23;
text-align: left;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.divesrr{
  border-radius: 50px;
background: #D3CCF5;
width: 30px;
height: 12px;
}
.frstTh{
  border: 1px solid var(--Neutral-100, #E7EAEE);
  width: 205px;
height: 60px;
display: flex;
padding: 8px 8px 8px 12px;
align-items: center;
background: #FAFAFA;
color: var(--Neutral-500, #64748B);
font-size: 14px;
font-weight: 600;
}
.frsttd{
  display: flex;
width: 205px;
height: 52px;
padding: 16px 12px;
align-items: center;
  border : 1px solid var(--Neutral-100, #E7EAEE);
background: var(--Shades-White, #FFF);
overflow: hidden;
color: var(--Neutral-800, #191D23);
text-overflow: ellipsis;
white-space: nowrap;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.scndrd{  
  display: flex;
width: 205px;
height: 52px;
padding: 16px 12px;
align-items: center;
  border-top : 1px solid var(--Neutral-100, #E7EAEE);
  border-bottom : 1px solid var(--Neutral-100, #E7EAEE);
  overflow: hidden;
color: var(--Neutral-800, #191D23);
text-overflow: ellipsis;
white-space: nowrap;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
  border-right : 1px solid var(--Neutral-100, #E7EAEE);
background: var(--Shades-White, #FFF);
}
.todaying{
  color: #212529;
font-size: 24px;
font-weight: 600;
}
.inputsearch{
  border-radius: 8px;
background: #FAFAFA;
width: 356px;
margin-bottom: 12px;
padding: 12px 12px 12px 12px;
gap: 10px;
display: flex;
margin-top: 34px;
height: 43px;
}
.boding{
  color: #374253;
font-size: 14px;
font-style: normal;
font-weight: 500;
}
.normalTextr{
  color: #374253;
font-size: 14px;
font-style: normal;
font-weight: 400;
}
.lightingg{
color: #939393;
font-size: 14px;
font-style: normal;
font-weight: 400;
}
.searching{
  background: #FAFAFA;
  border: none;
}
.searching:focus {
  outline: none;
  background: #FAFAFA;
}
.inner{
  color: #333;
text-align: right;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.tabliHead{
  color: #212121;
text-align: right;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.progress{
  border-radius: 2px;
  height: 10px;
  --mdb-progress-bar-bg: #084BF7;
  --mdb-progress-bg: white;
}
.nummm{
  color: #333;
text-align: right;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.secondth{
  border-top: 1px solid var(--Neutral-100, #E7EAEE);
border-right: 1px solid var(--Neutral-100, #E7EAEE);
border-bottom: 1px solid var(--Neutral-100, #E7EAEE);
display: flex;
width: 205px;
background: #FAFAFA;
height: 60px;
padding: 8px 8px 8px 12px;
align-items: center;
color: var(--Neutral-500, #64748B);
font-size: 14px;
font-weight: 600;
}

.noDealss{
  color: #2C343A;
font-size: 20px;
font-weight: 500;
}
.css-10vg0a6-MuiResponsiveChart-container{
  width: 1198px !important;
  margin-left: 0.5rem;
  overflow-y: scroll;
}
.dropdown-item{
--mdb-dropdown-state-background-color:"white"
}
.desccdeal{
  color: #454545;
font-size: 14px;
font-weight: 400;
}
.css-1eg20ca-MuiResponsiveChart-container{
  width: 860px !important;
}
.css-1qdzy9k-MuiBarElement-root{
  width: 57px !important;
  fill:#1B59F8 !important;
}
.lightDivesrr{
  border-radius: 50px;
background: #ECE9FF;
width: 30px;
height: 12px;
}
.fromi{
  color: #4F4F4F;
text-align: left;
padding-top: 12px;
font-size: 12px;
font-weight: 400; 
}
.todd{
  color: #2C343A;
  padding: 14px 20px;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.totCallsRed{
  color: #8D0801;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase;
}

  .AnalyticsMain {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5vw;
    /* padding: 10px; */
    /* padding-left: 8%; */
    padding-right: 8%;
    position: fixed;
    height: 75%;
    overflow: scroll;
    top: 10rem;
  }
  .totCallsBlu{
    color: #008CFE;
font-size: 13px;
font-weight: 500;
  }
  .totCallsgREEN{
    color: #52B788;
font-size: 13px;
font-weight: 500;
  }
  .totCallsdbLUE{
    color: #0466C8;
font-size: 13px;
font-weight: 500;
  }
  .blueDNum{
    color: #5B81F3;
font-size: 12px;
font-weight: 400;
  }
  .totResp{
    color: #009DA4;
font-size: 13px;
font-weight: 500;
  }
  .calss{
    color: #1C1C1C;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .setaii{
    color: #0084EF;
font-size: 14px;
font-weight: 500;
display: flex;
justify-content: center;
padding-top: 15px;
padding-bottom: 15px;
cursor: pointer;
text-decoration-line: underline;
  }
  .desci{
    color: #1C1C1C;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .boxess{
    width: 200px;
height: 120px;
border-radius: 8px;
border: 1px solid #F5F5F5;
padding: 16px 15px 16px 17px;
background: #FFF;
box-shadow: 0px 4px 8px 0px rgba(217, 218, 219, 0.20);
  }
  .greenNum{
    color: #32AA71;
font-size: 12px;
font-weight: 400;
  }
  .boldNm{

  }
  .reas{
    color: #383838;
font-size: 11px;
font-weight: 400;
  }
  .totalLeads {
    position: absolute;
    top: 80px;
    left: 62px;
    text-align: center;
  }
  .cardBody {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10%;
  }
  .boldNm{
    color: #2C343A;
font-size: 24px;
font-weight: 600;
  }
  .totCalls{
    color: #5A189A;
font-size: 13px;
font-weight: 500;
  }
  .lasdivest{
display: flex;
gap: 4px;
  }
  .greyLinee{
    width: 158px;
height: 1px;
background: #EDEDED;
margin-bottom: 8px;
  }