.cardMain {
    width: 600px;
    left: 327px;
    top: 320px;
  
    background: #ffffff;
  
    border: 0.964695px solid #eff0f6;
    /* box-shadow: 0px 4.82347px 19.2939px rgba(0, 0, 0, 0.05); */
    border-radius: 5px;
  }
  
  .AnalyticsMain {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5vw;
    /* padding: 10px; */
    padding-left: 8%;
    padding-right: 8%;
    position: fixed;
    height: 75%;
    overflow: scroll;
    top: 10rem;
  }
  
  .cardHeading {
    font-weight: 600;
    font-size: 16px;
    padding-left: 10%;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5);
  }
  
  .cardHeader {
    padding: 15px;
    border-bottom: 1px solid #eeeeee;
    height: 50px;
  }
  
  .cardBody {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10%;
  }
  
  .statusBars1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 221.75px;
    height: 40px;
    background: #f2f4f8;
    border-radius: 10.1566px;
    padding: 12px;
    margin-bottom: 10px;
  }
  
  .statusBars2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 221.75px;
    height: 40px;
    background: #fde7ea;
    border-radius: 10.1566px;
    padding: 12px;
    margin-bottom: 10px;
  }
  
  .statusBars3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 221.75px;
    height: 40px;
    background: #def8ed;
    border-radius: 10.1566px;
    padding: 12px;
    margin-bottom: 10px;
  }
  
  .statusBars4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 221.75px;
    height: 40px;
    background: #fffae5;
    border-radius: 10.1566px;
    padding: 12px;
    margin-bottom: 10px;
  }
  .statusBars5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 221.75px;
    height: 40px;
    background: #f4f8fc;
    border-radius: 10.1566px;
    padding: 12px;
    margin-bottom: 10px;
  }
  
  .statusBarHeading1 {
    font-weight: 500;
    font-size: 11.5px;
    color: #71a5de;
  }
  
  .statusBarHeading2 {
    font-weight: 500;
    font-size: 11.5px;
    color: #ef233c;
  }
  
  .statusBarHeading3 {
    font-weight: 500;
    font-size: 11.5px;
    color: #26ad74;
  }
  
  .statusBarHeading4 {
    font-weight: 500;
    font-size: 11.5px;
    color: #f5b82e;
  }
  .statusBarHeading5 {
    font-weight: 500;
    font-size: 11.5px;
    color: #0084EF;
  }
  
  .cardFooter {
    padding: 15px;
    border-top: 1px solid #eeeeee;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .cardFooter2 {
    padding: 15px;
    border-top: 1px solid #eeeeee;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 13%;
  }
  
  .interested {
    width: 40px;
    height: 10px;
    background: #26ad74;
    border-radius: 95.4395px;
    margin-right: 10px;
  }
  
  .interested2 {
    width: 40px;
    height: 10px;
    background: #26ad74;
    border-radius: 95.4395px;
    margin-right: 10px;
  }
  
  .contacted {
    width: 40px;
    height: 10px;
    background: rgb(238, 238, 238);
    border-radius: 95.4395px;
    margin-right: 10px;
  }
  
  .totalResponse {
    width: 40px;
    height: 10px;
    background: #be3455;
    border-radius: 95.4395px;
    margin-right: 10px;
  }
  
  .won {
    width: 40px;
    height: 10px;
    background: rgb(86, 86, 96);
    border-radius: 95.4395px;
    margin-right: 10px;
  }
  
  .BottomText {
    font-weight: 500;
    font-size: 12px;
    color: #838383;
    margin-right: 20px;
  }
  
  .totalLeads {
    position: absolute;
    top: 80px;
    left: 62px;
    text-align: center;
  }
@media screen and (min-device-width: 600px) and (max-device-width: 1024px) {
  .cardMain {
    width: 400px;
    left: 227px;
    top: 320px;
  }
  
  .AnalyticsMain {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3vw;
    padding: 10px;
    height: 75%;
    top: 10rem;
  }
  
  .cardHeading {
    font-weight: 600;
    font-size: 14px;
    padding-left: 10%;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5);
  }
  
  .cardHeader {
    padding: 15px;
    border-bottom: 1px solid #eeeeee;
    height: 50px;
  }
  
  .cardBody {
    height: 250px;
    padding-left: 0%;
  }
  
  .statusBars1 {
    width: 151.75px;
    height: 30px;
    padding: 8px;
  }
  
  .statusBars2 {
    width: 151.75px;
    height: 30px;
    padding: 8px;
    margin-bottom: 10px;
  }
  
  .statusBars3 {
    width: 151.75px;
    height: 30px;
    background: #def8ed;
    border-radius: 10.1566px;
    padding: 8px;
    margin-bottom: 10px;
  }
  
  .statusBars4 {
    width: 151.75px;
    height: 30px;
    background: #fffae5;
    border-radius: 10.1566px;
    padding: 8px;
    margin-bottom: 10px;
  }
  
  .statusBars5 {
    width: 151.75px;
    height: 30px;
    background: #fffae5;
    border-radius: 10.1566px;
    padding: 8px;
    margin-bottom: 10px;
  }
  
  .statusBarHeading1 {
    font-weight: 500;
    font-size: 11.5px;
  }
  
  .statusBarHeading2 {
    font-weight: 500;
    font-size: 11.5px;
  }
  
  .statusBarHeading3 {
    font-weight: 500;
    font-size: 11.5px;
  }
  
  .statusBarHeading4 {
    font-weight: 500;
    font-size: 11.5px;
  }
  
  .cardFooter {
    padding: 15px;
    height: 50px;
  }
  
  .cardFooter2 {
    padding: 10px;
    height: 50px;
    padding-left: 13%;
  }
  
  .interested {
    width: 40px;
    height: 10px;
    margin-right: 10px;
  }
  
  .interested2 {
    width: 40px;
    height: 10px;
    margin-right: 10px;
  }
  
  .contacted {
    width: 40px;
    height: 10px;
    margin-right: 10px;
  }
  
  .totalResponse {
    width: 40px;
    height: 10px;
    margin-right: 10px;
  }
  
  .won {
    width: 40px;
    height: 10px;
    margin-right: 10px;
  }
  
  .BottomText {
    font-weight: 500;
    font-size: 12px;
    color: #838383;
    margin-right: 20px;
  }
  
  .totalLeads {
    position: absolute;
    top: 80px;
    left: 62px;
    text-align: center;
  }
}