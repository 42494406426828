.lead_details_left{
    width: 23%;
    border-right: 1px solid #DEDEDE;
    padding: 1rem;
}
.lead_details_right{
    padding: 1.5rem;
    width: 72%;
}
.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input{
    font-size: 14px;
}
.lead_details_heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.fixedWidthTimePicker {
    width: 132px; /* Set your desired width */
  }
  
.searchinput {
    border: none;
    width: 139px;
}
  
.searchinput:focus {
  outline: none;
}
.lead_details_heading > h5{
    font-family: Inter;
font-size: 20px;
font-weight: 500;
line-height: 24.2px;
letter-spacing: 0.012em;
text-align: left;
color: #191D23;
}

.lead_details_heading_drop{
    padding: 4px;
    position: relative;
    top: -8px;
    border: 1px solid rgb(222, 222, 222);
    border-radius: 50px;
    box-shadow: none;
}
.lead_details_heading_stage{
    display: flex;
    gap: 30%;
    align-items: center;
    margin: 0.5rem 0rem;
}
.lead_details_heading_stage > div,
.lead_details_closure > div{
    font-family: Inter;
font-size: 12px;
font-weight: 600;
line-height: 14.52px;
text-align: left;
color: #191D23;
}
.leadDetails_status{

    font-family: Inter;
font-size: 12px;
font-weight: 600;
line-height: 14.52px;
text-align: left;
color: #191D23;
}
.leadDetails_stage{
    
    font-family: Inter;
font-size: 12px;
font-weight: 600;
line-height: 14.52px;
text-align: left;
color: #191D23;
}
.lead_details_stage_drop_btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: none;
}
.lead_stage_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.5px solid #00000040;
    padding: 5px;
    gap: 40px;
    border-radius: 4px;
}

.lead_details_closure_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.5px solid #00000040;
    padding: 5px;
    gap: 10px;
    border-radius: 4px;
}
.lead_stage_div > div, 
.lead_details_closure_div > div{
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: #212121;
}

.lead_details_closure{
    display: flex;
    gap: 4%;
    align-items: center;
    margin: 1rem 0rem;
}
.lead_details{
    font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 16.94px;
text-align: left;
color: #191D23;
margin-top: 2rem;
}
.lead_conditions{
    display: flex;
    align-items: center;
    gap: 10px;
}
.lead_conditions > div{
padding: 6px 10px 6px 10px;
border-radius: 4px ;
font-family: Inter;
font-size: 12px;
font-weight: 500;
line-height: 14.52px;
text-align: left;
}
.lead_conditions > .condition_warm{
    color: #EF8F00;

background: #FFF5E5;

}
.lead_conditions > .condition_cold{
    color: #46AF4A;
    background: #EDF8ED;
}
.leadKaname{
    font-size: 15px;
    text-wrap: nowrap;
    width: 216px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.lead_conditions > .condition_priority{
    color: #850F8D;
    background: #FBE8FD;
}
.lead_conditions > .condition_hot{
    color: #114F5B;
    background: #EAF8FB;
}
.add_tag{
    display: flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #F5FAFF;
    color: #0084EF;
    margin: 1rem 0rem;
    width: 40%;
}
.add_tag > div{
    color: #0084EF;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.lead_contact > div{
    display: flex;
    align-items: center;
    color: #212121;
    gap: 8px;
    margin: 0.5rem 0rem;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.lead_contact_icon_div{
    display: flex;
padding: 6px;
align-items: flex-start;
gap: 10px;border-radius: 50px;
background: #E5F4FF;
}
.remark_div{
    display: flex;
    padding: 12px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 12px;
    background: #E5F4FF;
}
.remark_div > h6{
    color: #454545;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.remark_div > div{
    color: #333;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.lead_details_pipeline_div{
    display: flex;
    gap: 40px;
    color: #737373;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.lead_details_log_history{
    display: flex;
padding: 16px;
justify-content: center;
align-items: center;
gap: 10px;
border-bottom: 2px solid #0084EF;
color: #0084EF;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
    width: 13%;
    margin: 2% 0%;
    /* padding: 0% 5%; */
}
.viewDatil{
    color: #0084EF;
font-size: 10px;
font-weight: 400;
border-radius: 2px;
background: #E5F4FF;
padding: 4px 6px;
width: 70px;
height: 20px;
display: flex;
cursor: pointer;
justify-content: center;
align-items: center;
}
.whitebox{
display: flex;
padding: 10px 8px;
width: 922px;
flex-direction: column;
justify-content: center;
gap: 12px;
border-radius: 8px;
background: #FFF;
}
.tecty{
    color: #1C2126;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
    margin: 4% 0%;
}

.lead_details_today {
  display: flex;
  align-items: center;
  justify-content: center;
}

.textareaCustom:focus {
    border-color: blue;
    outline: none; /* To remove the default outline on focus */
  }
.today_line {
  flex: 1;
  height: 1px;
  background-color: #DEDEDE; 
}
.timing{
    color: #5E5E5E;
font-size: 11px;
font-weight: 400;
}
.nameing{
    color: #0084EF;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.blacking{
    color: #333;
font-size: 12px;
font-weight: 500;
}
.statuses{
    color: #333;
font-size: 11px;
font-weight: 400;
}
.greyboxx{
    display: flex;
padding: 12px 16px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
border-radius: 8px;
background: #FAFAFA;
}
.today_text {
  margin: 0 10px; 
  white-space: nowrap; 
  color: #454545;
font-family: Inter;
font-size: 11px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.data_div {
    display: flex;
    padding: 12px 16px;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: #FAFAFA;
    margin: 1rem 0rem;
    justify-content: space-between;
    align-items: center;
}

.date_time{
    display: flex;
    color: #5E5E5E;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 0.7rem;
}
.author{
    color: #0084EF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.message{
    color: #333;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.amount{
    color: #0084EF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.status{
    color: #333;
font-family: Inter;
font-size: 11px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.lead_detail_div{
    display: flex;
align-items: flex-start;
color: #3C4553;
font-family: Inter;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 2% 0%;
}
.lead_detail_div > div{
    width: 50%;
} 
.view_details{
    display: flex;
padding: 4px 6px;
align-items: center;
gap: 2px;
border-radius: 2px;
background: #E5F4FF;
color: #0084EF;
font-family: Inter;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.zeroState{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.zeroState > p{
    color: #7A7A7A;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.accordion-button {
    color: #000000;
  }
  
  .accordion-button:not(.collapsed) {
    color: black; /* Change the color when opened */
  }
  
  .accordion-button::after {
    content: "";
    border: solid #000000; /* Default color of the arrow */
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    transition: transform 0.2s;
    margin-left: 10px; /* Adjust as needed */
  }
  
  .accordion-button:not(.collapsed)::after {
    transform: rotate(-135deg); /* Rotate the arrow when opened */
  }
  
  /* Base styles */
.leadDetailsContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.leadDetailsLeft {
    width: 100%;
    border-right: 1px solid #DEDEDE;
    padding: 1rem;
}

.leadDetailsRight {
    width: 100%;
    padding: 1.5rem;
}
.offcanvasStage {
    display: flex;
    flex-direction: column;
    width: 155px;
  }
  .offcanvasSelectStage {
    padding: 6px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #00000040;
    cursor: pointer;
  }
  
.offcanvasCaretDown {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    color: #343330;
  }
  
.offcanvasStageDropdown {
    z-index: 10;
    display: flex;
    width: 314px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0px 0px 8px 8px;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(137, 137, 137, 0.25);
  }
  
.offcanvasStageOption {
    cursor: pointer;
    white-space: pre-wrap;
    display: flex;
    padding: 8px 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    background: #fff;
  }
  
.offcanvasStatus {
    display: flex;
    flex-direction: column;
    width: 155px;
  }
  .offcanvasSelectStatus {
    padding: 6px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #00000040;
    width: 155px;
  }
  
.offcanvasSelectedStatus {
    color: #212121;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
.offcanvasStatusDropdown {
    z-index: 5;
    display: flex;
    width: 211px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0px 0px 8px 8px;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(137, 137, 137, 0.25);
  }
  .offcanvasStatusOption {
    display: flex;
    width: 187px;
    padding: 8px 10px;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #fff;
    color: #404040;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */
    letter-spacing: 0.5px;
  }
  .additionalDetails_div{
    display: flex;
align-items: flex-start;
margin-bottom: 12px;
  }
  .additionalDetails_paraKey{
    color: #3C4553;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 66%;
  }

  .additionalDetails_paraValue{
    color: #3C4553;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 40%;
  }
 
  
.offcanvasDatePicker {
    border-radius: 4px;
    background: #fff;
    color: #454545;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.144px;
    width: 152px;
    height: 31px;
  }
  .lead_details_datePick{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.5px solid #00000040;
    padding: 5px;
    gap: 10px;
    border-radius: 4px;
  }
  
.offcanvasPrevTags {
    width: 100%;
    border: 1px solid #0084ef;
    padding: 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    background-color: white;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }

  
.offcanvasSearchTag {
    width: 30%;
    border: none;
  }
  .offcanvasSearchTag:focus {
    outline: none;
  }