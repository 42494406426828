.addPipeline {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #0084ef;
  display: flex;
  width: 158px;
  cursor: pointer;
  height: 48px;
  background: #0084ef;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
}
.predModal {
  width: 650px;
  max-width: 650px;
}
.custom-textarea {
  height: 75px;
  width: 578px;
  margin-top: 13px;
  font-size: 13px;
  border: 0.75px solid #999;
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 18px;
  outline: none; /* Remove default focus outline */
}
.submii {
  border-radius: 8px;
  background: #0084ef;
  padding: 8px 24px;
  display: flex;
  width: 106px;
  height: 40px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.newStage {
  color: #0084ef;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
}
.bolding {
  color: #212529;
  font-size: 14px;
  font-weight: 500;
}
.stripss {
  width: 3px;
  height: 45px;
  border-radius: 0.75px;
  background: #af47d2;
}
.stripss1 {
  width: 3px;
  height: 45px;
  border-radius: 0.75px;
  background: #26355d;
}
.arrowGrey {
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
  padding: 8px;
  height: 34px;
  align-items: center;
  width: 34px;

  display: flex;
}
.arrowGreydownn{
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
  padding: 8px;
  height: 34px;
  align-items: center;
  width: 34px;

  display: flex;
}
.arrowGreydownn img {
  transition: transform 0.3s ease; /* Optional: for smooth rotation */
}

/* Add this class for rotation */
.arrowGreydownn.rotateDown img {
  transform: rotate(180deg); /* Rotate the image 180 degrees */
}
.unassign {
  border-radius: 4px;
  border: 0.75px solid #ededed;
  background: #fafafa;
  display: flex;
  margin-left: 6px;
  width: 440px;
  color: #8e8e8e;
  font-size: 14px;
  height: 45px;
  font-weight: 500;
  padding: 14px 16px;
  align-items: center;
}
.unassignBlack {
  border-radius: 4px;
  border: 0.75px solid #999;
  display: flex;
  margin-left: 6px;
  width: 440px;
  color: #4d4d4d;
  font-size: 14px;
  height: 45px;
  font-weight: 500;
  padding: 14px 16px;
  align-items: center;
}

.unassignBlack:focus {
  outline: 0.75px solid #999;
  /* background: #FAFAFA; */
}
.noteHead {
  color: #212121;
  width: 502px;
  font-size: 12px;
  display: flex;
  font-weight: 400;
  position: relative;
}

.blueR {
  color: #0084ef;
  font-size: 10px;
  font-weight: 300;
  text-decoration-line: underline;
  cursor: pointer;
}
.pipelinVal {
  color: #3d454c;
  font-size: 16px;
  font-weight: 500;
}
.pipingss {
  color: #212529;
  font-size: 24px;
  font-weight: 600;
}
.brieff {
  color: #212121;
  font-size: 12px;
  font-weight: 300;
  display: inline-block;
}

.collapsed {
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.expanded {
  white-space: normal;
}

.wraping {
  padding-right: 8px;
  text-wrap: nowrap;
}
.stages {
  color: #575757;
  font-family: Inter;
  font-size: 14px;
  padding-top: 14px;
  padding-bottom: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.5px;
}
.bottDiv {
  display: flex;
  gap: 12px;
  padding-right: 26px;
  justify-content: end;
  border-top: 1px solid #dedede;
  padding: 22px;
}
.custom-textarea:focus {
  border: 0.75px solid #999; /* Keep the same border on focus */
}

.offcanvasBody {
  /* overflow-x: hidden !important; */
  overflow-y: scroll;
}

.offcanvasBody::-webkit-scrollbar {
  display: block !important;
  width: 6px;
  height: 10px;
  transition: all;
  transition-duration: 250ms;
}

/* Track */
.offcanvasBody::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.offcanvasBody::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/* Handle on hover */
.offcanvasBody::-webkit-scrollbar-thumb:hover {
  background: #d8d8d8;
}

.offcanvasBody::-webkit-scrollbar-thumb:focus {
  background: #bebebe;
}

.cancy {
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
  display: flex;
  cursor: pointer;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  width: 103px;
  height: 40px;
  color: #616060;
  font-size: 16px;
  font-weight: 400;
}
.offcanvasBody {
  overflow-x: hidden !important;
  overflow-y: scroll;
}

.offcanvasBody::-webkit-scrollbar {
  display: block !important;
  width: 6px;
  height: 10px;
  transition: all;
  transition-duration: 250ms;
}

/* Track */
.offcanvasBody::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.offcanvasBody::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/* Handle on hover */
.offcanvasBody::-webkit-scrollbar-thumb:hover {
  background: #d8d8d8;
}

.offcanvasBody::-webkit-scrollbar-thumb:focus {
  background: #bebebe;
}

.add_head {
  color: #212529;
  font-size: 24px;
  font-weight: 600;
  border-bottom: 1px solid #dedede;
  width: 650px;
  padding: 24px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.boldHeaad {
  color: #4a4a4a;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

.kanbanStageMain {
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding-bottom: 15px;
  border-bottom: 1px solid #dedede;
}
.kanbanStageName {
  color: #212529;
  font-weight: 500;
  font-size: 20px;
  max-width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.kanbanStageRevenue {
  display: flex;
  gap: 8px;
}

.kanbanItemTitle1 {
  display: inline-flex;
  align-items: flex-start;
  gap: 6px;
}
.kanbanItemName {
  color: #3d454c;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.kanbanItemLastUpdate {
  color: #64748b;
  font-family: Inter;
  padding-top: 4px;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.132px;
}
.kanbanItemAmount {
  color: #0084ef;
  font-family: Inter;
  font-size: 12px;
  padding-top: 3pxx;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.kanbanItemStatus {
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
   background: #e5f4ff;
  color: #0084ef; 
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.kanbanItemDesc1 {
  display: flex;
  align-items: center;
  gap: 6px;
}
.kanbanItemTag {
  display: flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  /* color: #114F5B; */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.kanbanItemNo {
  color: #0084ef;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.144px;
}
.react-kanban-board{
  height: 70vh;
  width: 95vw;
  overflow-y: auto!important;
  overflow: scroll;
 
}

.react-kanban-board::-webkit-scrollbar {
  display: block !important;
  width: 0px;
  height: 10px;
  transition: all;
  transition-duration: 250ms;
}

/* Track */
.react-kanban-board::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.react-kanban-board::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/* Handle on hover */
.react-kanban-board::-webkit-scrollbar-thumb:hover {
  background: #d8d8d8;
}

.react-kanban-board::-webkit-scrollbar-thumb:focus {
  background: #bebebe;
}
.react-kanban-column {
  background-color: transparent !important;
  border: 1px solid #dedede;
  border-bottom: none;
  min-height: 2000px !important;
  padding: 11px !important;
}
.kanbanItemLastUpdate{
  font-size: 11px;
}
.kanbanItemAmount{
  text-wrap: nowrap;
    max-width: 90px;
    overflow: hidden;
    line-height: normal;
    text-overflow: ellipsis;}
.kanbanItemName{
  text-wrap: nowrap;
    max-width: 87px;
    overflow: hidden;
    line-height: normal;
    text-overflow: ellipsis;
}
.react-kanban-card {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(137, 137, 137, 0.25);
  width: 238px;
}
.react-kanban-card{
  max-width: 238px !important;
  min-width: 235px !important;
}
.react-kanban-card__title span {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}
.react-kanban-card__description {
  display: flex;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid #dedede;
}
