.activeTab {
  color: #0084ef;
  font-family: Inter;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.0105rem;
  border-radius: 4px;
  background: #f0f9ff;
  display: flex;
  justify-content: center;
  padding: 10px 16px;
  align-items: center;
  gap: 0.5rem;
  transition: 0.5s all;
  cursor: pointer;
}
.xls_line {
  color: #b6bbcc;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.rightTabContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}
.xls_download {
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-200, #e0e3e6);
  background: var(--White, #fff);
  color: var(--Neutral-800, #40454a);
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

.xls_import {
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Primary-500, #4a68ff);
  background: var(--White, #fff);
  color: #0084ef;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.inactiveTab {
  border-radius: 4px;
  background: #fcfcfc;
  font-family: Inter;
  color: #666464;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0105rem;
  background: #fcfcfc;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: 0.5s all;
  cursor: pointer;
}
.import_csv {
  cursor: pointer;
}
.csv_text {
  color: #0165b6;

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.export_csv {
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Primary-50, #f9f5ff);
  background: var(--Primary-50, #f9f5ff);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.tabs__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  /* width: 93vw; */
  position: fixed;
  /* margin-left: 14.5%; */
}
.tab__selected {
  cursor: pointer;
  border-radius: 50px;
  border: 1px solid #0084ef;
  background: #fff;
  padding: 6px 16px;
  letter-spacing: 0.168px;
  display: flex;
  color: #0084ef;
  line-height: 16.94px;
  align-items: center;
  font-size: 14px;
}
.tabDim1 {
  height: 29px;
  width: 94px;
}
.tabDim2 {
  height: 29px;
  width: 160px;
  white-space: nowrap;
}
.tab__not__selcted {
  letter-spacing: 0.168px;
  display: flex;
  cursor: pointer;
  border-radius: 50px;
  line-height: 16.94px;
  align-items: center;
  border: 1px solid #eeeeef;
  background: #fff;
  padding: 6px 16px;
  font-size: 14px;
}
.leadManagementContainer {
}
.btn.active,
.btn:active {
  box-shadow: none;
}
.sideBarPanel {
  /* width: 14.5vw; */
  /* border-right: 1px solid #eeeeef; */
  /* padding: 16px; */
  padding-top: 12px;
  padding-left: 16px;
  padding-right: 16px;
  position: fixed;
  display: flex;
  padding-bottom: 14px;
  gap: 12px;
  margin-left: 1.2rem;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  position: fixed;
  /* height: 100vh; */
}
.stages {
  display: flex;
  gap: 10px;
}
.unassignedButton {
  color: #212427;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
  margin-right: 20px;
  letter-spacing: 0.168px;
}

@media screen and (min-device-width: 600px) and (max-device-width: 1024px) {
  .activeTab {
    font-size: 0.6rem;
  }
  .inactiveTab {
    font-size: 0.6rem;
  }
  .tab__selected {
    cursor: pointer;
    border-radius: 50px;
    border: 1px solid #0084ef;
    background: #fff;
    padding: 4px 16px;
    letter-spacing: 0.168px;
    display: flex;
    color: #0084ef;
    line-height: 16.94px;
    align-items: center;
    font-size: 12px;
  }
  .tab__not__selcted {
    letter-spacing: 0.168px;
    display: flex;
    cursor: pointer;
    border-radius: 50px;
    line-height: 16.94px;
    align-items: center;
    border: 1px solid #eeeeef;
    background: #fff;
    padding: 6px 16px;
    font-size: 12px;
  }
  .tabDim1 {
    height: 29px;
    width: 90px;
  }
  .tabDim2 {
    height: 29px;
    width: 150px;
  }
}

.followUpFilter {
  height: 12px;
  width: 12px;
  cursor: pointer;
}
.customtooltip.bs-tooltip-top .tooltip-inner {
  background-color: black !important;
  color: white !important;
}

.customtooltip .arrow::before {
  border-top-color: black !important;
}
.sendAlert {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 6px 17px;
  background-color: #fffbf5;
  height: 30px;
  width: 46px;
  border-radius: 50px;
  color: #ff9900;
  font-size: 12px;
  font-weight: 500;
}
.sendAlert:hover{
  box-shadow: 0px 4px 4px 0px #00000040;

}

.followScroll::-webkit-scrollbar {
  display: block !important;
  width: 6px;
  height: 10px;
  transition: all;
  transition-duration: 250ms;
}

/* Track */
.followScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.followScroll::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/* Handle on hover */
.followScroll::-webkit-scrollbar-thumb:hover {
  background: #d8d8d8;
}

.followScroll::-webkit-scrollbar-thumb:focus {
  background: #bebebe;
}
.sendAlertWords {
  display: none;
  white-space: nowrap; /* Prevent the text from wrapping */
  overflow: hidden;
  transition: width 0.3s ease;
  width: auto;
}

.sendAlert:hover .sendAlertWords {
  display: block;
}

.followUp {
  width: 45px;
  margin-left: auto;
  margin-right: auto;
}

/* .followUp:hover {
  width: 108px;
} */

.addClosure {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  border-radius: 8px;
  color: #0084ef;
  font-size: 12px;
  font-weight: 400;
}

.addClosureWords {
  display: none;
  white-space: nowrap; /* Prevent the text from wrapping */
  overflow: hidden;
  transition: width 0.3s ease;
  width: auto;
}

.addClosure:hover .addClosureWords {
  display: block;
}

.addClosure:hover {
  border: 1px solid #0084ef;
  background-color: #e5f4ff;
}

.addClosureIcon {
  height: 18px;
  width: 18px;
}

.addClosure:hover .addClosureIcon {
  height: 12px;
  width: 12px;
}

.viewRemark {
  padding: 6px 8px;
  background-color: #f1e5ff;
  border-radius: 50px;
  color: #9747ff;
  font-size: 12px;
  font-weight: 500;
}

.viewRemarkWords {
  display: none;
  white-space: nowrap; /* Prevent the text from wrapping */
  overflow: hidden;
  transition: width 0.3s ease;
  width: auto;
}

.viewRemark:hover .viewRemarkWords {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.viewRemark:hover .viewRemarkIcon {
  display: none;
}

.offcanvasName {
  color: #191d23;
  width: 277px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.offcanvasLastActivity {
  color: #333;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.offcanvasCollapse {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background-color: white;
  border: none;
  color: #0084ef;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.offcanvasClose {
  color: #454545;
  font-weight: 400;
  font-size: 14px;
  background-color: white;
  border: none;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
.offcanvasHeaderOptions {
  color: #363535;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.144px;
}
.offcanvasHeaderOptionsIcon {
  width: 14px;
  height: 14px;
}
.offcanvasHeaderOptionsItem {
  display: flex;
  align-items: center;
  gap: 8px;
}
.offcanvasStage {
  display: flex;
  flex-direction: column;
  width: 265px;
}
.offcanvasSelectStage {
  padding: 12px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #00000040;
  cursor: pointer;
}
.offcanvasDetails {
  display: flex;
  padding: 5px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 0.5px solid var(--blue, #0084ef);
  background-color: #fff;
  font-size: 12px;
  font-weight: 300;
  color: #0084ef;
}
.offcanvasStageDropdown {
  z-index: 10;
  display: flex;
  width: 314px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0px 0px 8px 8px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(137, 137, 137, 0.25);
}
.offcanvasStageDropdown2 {
  z-index: 10;
  display: flex;
  width: 314px;
  padding: 12px;
  overflow: scroll;
  max-height: 200px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0px 0px 8px 8px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(137, 137, 137, 0.25);
}
.offcanvasStageOption {
  cursor: pointer;
  display: flex;
  padding: 8px 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: #fff;
}
.offcanvasStatus {
  display: flex;
  flex-direction: column;
  width: 265px;
}
.offcanvasSelectStatus {
  padding: 12px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #00000040;
  width: 250px;
}
.offcanvasStatusDropdown {
  z-index: 5;
  display: flex;
  width: 211px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0px 0px 8px 8px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(137, 137, 137, 0.25);
}
.offcanvasStatusOption {
  display: flex;
  width: 187px;
  padding: 8px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #fff;
  color: #404040;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  letter-spacing: 0.5px;
}
.offcanvasCaretDown {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  color: #343330;
}
.offcanvasSelectedStatus {
  color: #212121;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.offcanvasRemarkToggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #191d23;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}
.offcanvasRemarkMenu {
  display: flex;
  width: 156px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0px 0px 8px 8px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(137, 137, 137, 0.25);
}
.offcanvasRemarkItem {
  display: flex;
  padding: 8px 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: #fff;
  color: #404040;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  letter-spacing: 0.5px;
}
.offcanvasRemarkDropdown {
  display: flex;
  /* width: 236px; */
  padding: 12px 12px 12px 0px;
  flex-direction: column;
  align-items: flex-start;
}
.offcanvasDatePicker {
  border-radius: 4px;
  border: 1px solid #dedede;
  background: #fff;
  color: #454545;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.144px;
  width: 129px;
  height: 31px;
}
.offcanvasDetails1 {
  color: #454545;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.offcanvasSearchTag {
  width: 30%;
  border: none;
}
.offcanvasSearchTag:focus {
  outline: none;
}
.offcanvasPrevTags {
  width: 100%;
  border: 1px solid #0084ef;
  padding: 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  background-color: white;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.offcanvasLogHistoryMain {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  gap: 12px;
}
.textareaCustom:focus {
  border-color: blue;
  outline: blue; /* To remove the default outline on focus */
}
.offcanvasLogHistoryLog {
  display: flex;
  padding: 6px 20px;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 13px;
  gap: 12px;
  border-bottom: 1px solid #dedede;
  width: 100%;
}
.offcanvasLogHistoryDiv1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.offcanvasLogHistoryName {
  color: #191d23;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.offcanvasLogHistoryDateTime {
  color: #485360;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.offcanvasLogHistoryDiv2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.offcanvasLogHistorySpan {
  color: #485360;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.offcanvasLogHistoryStage {
  display: flex;
  padding: 4px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  font-size: 12px;
}
.offcanvasLogHistoryDiv3 {
  display: flex;
  width: 496px;
  padding: 10px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: 4px;
  background: #fafafa;
}
.offcanvasLogHistoryDiv3Div1 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.offcanvasLogHistoryDiv3Div {
  color: #1c2126;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.offcanvasLogHistoryDiv3Div2 {
  color: #212121;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.offcanvasSendEmail {
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  color: #0084ef;
  font-size: 16px;
  font-weight: 400;
  background-color: white;
  border: 1px solid white;
}
.offcanvasSendEmail:hover {
  border: 1px solid #0084ef;
  background: #e5f4ff;
}
.offcanvasSendQuotation {
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  color: #0084ef;
  font-size: 16px;
  font-weight: 400;
  background-color: white;
  border: 1px solid white;
}
.offcanvasSendQuotation:hover {
  border: 1px solid #0084ef;
  background: #e5f4ff;
}

.leadSelectedMain {
  display: inline-flex;
  padding-left: 8px;
  align-items: center;
  gap: 4px;
  border-radius: 50px;
  background: #f7f7f7;
}
.leadsSelected {
  display: flex;
  height: 32px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #f7f7f7;
  color: #333639;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.144px;
}
.leadOptions {
  display: flex;
  padding: 2px 4px;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: #fff;
  box-shadow: -4px 0px 16px 0px rgba(0, 0, 0, 0.15);
}
.leadSelectAll {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 50px;
  background: #fff;
  color: #515a64;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.144px;
}
.leadSelectAllActive {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 50px;
  background: #fff;
  color: #0084ef;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.144px;
}
.leadOther {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 50px;
  background: #fff;
  color: #333639;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.144px;
}
.leadSelectAll:hover,
.leadOther:hover {
  background: #e9ebec;
  color: #515a64;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.144px;
}
.leadSelectAll:active,
.leadOther:active {
  background: #e9ebec;
  color: #0084ef;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.144px;
}
.leadOptionIcon {
  height: 16px;
  width: 16px;
  color: inherit;
}

.datePickerHeading {
  color: #212121;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  padding-bottom: 10px;
}

.datePicker {
  visibility: hidden;
  height: 0;
  padding: 0;
  width: 0;
  position: absolute;
}
