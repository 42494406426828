.container {
  position: fixed;
  top: 0;
  left: 0;
 
}

.header {
  height: 3rem;
  width: 100vw;
  /* border-bottom: 1px solid #a7a7a7; */
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  /* padding-top: 12px; */
  /* padding-right: 20px; */
  /* padding-left: 1rem; */
}

.sidebar {
  height: 90vh;
  width: 20vw;
  border-right: 1px solid #dfdfdf;
  background-color: #222338;
}

.sidebarCollapsed {
  height: 90vh;
  width: 55px;
  border-right: 1px solid #dfdfdf;
  background-color: #222338;
}

.header_remaining {

  position: relative;
  width: 81%;
  height: 80%;
  border-bottom: 1px solid #a7a7a7;

}
.header_remaining_coll {

  position: relative;
  width: 97%;
  height: 100%;
  border-bottom: 1px solid #a7a7a7;

}

.logobox {
  width: 20.2%;
  /* margin-left: 1rem; */
  display: flex;
align-items: center;
  height: 130%;
  background-color: #222338;
}

.logobox img {
  margin-left: 20%;
  margin-bottom: 3px;
  color: #FFFFFF;
  
}

.profilebox {
  width: 96%;
  
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  
}

.menubox {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}

.eachHeaderLink {
  font-weight: 500;
  font-size: 16px;
  margin-right: 2rem;
}

.eachHeaderLink a {
  text-decoration: none;
  color: #444444;
}

.notification {
  color: #3b5998;
  margin-right: 0.8rem;
  font-size: 20px;
  
}

.cart {
  color: #3b5998;
  margin-right: 1.5rem;
  font-size: 20px;
}

.profileNameBox {
  cursor: pointer;
  position: relative;
  user-select: none;
  margin-bottom: 3px;
  margin-right: 3px;
}

.profileName {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5%
}

.dropdown {
  position: absolute;
  background: #FFFFFF;
  text-align: left;
  box-shadow: 0px 2px 16px 0px #9AAACF33;
  border: 1px solid #E6E6E6;
  border-radius: 8px;
  width: 187px;
  right: 5px;
  font-size: 1rem;
  /* bottom: 100%; */
  /* top: 40px; */
  padding: 12px 8px;
  margin-top: 8%;

}

.dropdownItem {
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 6px;
  color: #333;
  font-size: 16px;
font-family: Inter;


}

.dropdownItem:hover {
  background-color: #00000021;
  /* padding-left: 50px important;  */
}
.upDownArrow{
  display: flex;
  flex-direction: column;
}

.planPill {
  font-size: 10px;
  background-color: #17609126;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  width: fit-content;
  padding: 0 1rem;
}

.sidebar {
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.createProjectButtonWrapper {
  padding: 0 0 0.8rem 0;
  
  display: flex;
  justify-content: center;
}

.createProjectButtonWrapper button {
  border: none;
  border-radius: 4px;
  background-color: #374151;
  padding: 0.5rem 1rem;
  font-size: 12px;
  font-weight: 500;
  color: #fafafa;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all;
  transition-duration: 300ms;
  width: 100%;
  justify-content: center;
}

.createProjectButtonWrapper button:hover {
  box-shadow: 4px 4px 10px #0000003f;
}

.linksContainerWrapper {
  height: 86%;
  /* overflow-y: scroll; */
  /* margin-top: -2%; */
}

.linksContainer {
  height: fit-content;
  padding: 0.5rem 0;
}

.sidebarLink {
  display: flex;
  padding: 0.4rem 0 0.4rem 0.5rem;
  align-items: center;
  text-decoration: none;
  color: #000000 !important;
  margin-bottom: 2%;
  border-radius: 8px;
  transition: all;
  transition-duration: 300ms;
}

.sidebarLink:hover {
  background-color: #6359E925;
}

.sidebarLabel {
  font-size: 14px;
  color: #FFFFFF;
}

.upgradeButton {
  font-size: 14px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #333333;
  padding: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all;
  transition-duration: 300ms;
}

.upgradeButton:hover {
  background-color: #003566;
  color: #ffffff;
  border: 1px solid #003566;
}

.upgradeText {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  margin-top: 1rem;
  align-items: center;
}

.rmName {
  font-size: 14px;
  font-weight: 500;
}

.rmPost {
  font-size: 12px;
  color: #9c9c9c;
}

.activeSidebarItem {
  background-color: #6076FF !important;
  border-radius: 6px;
}

/* Notification */
.notifyContainer {
  /* border: 2px solid red; */
  /* position: absolute; */
  top: 10%;
  right: 3%;
  height: 88vh;
  width: 22vw;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.notification_modal{
  width: 22vw;
  left: 27%;
  top: 1.6%;
  
}
.headingContainer {
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1d3557;
  border-radius: 8px 8px 0px 0px;
  color: white;
  height: 4rem;

}


.notifyHeading {
  /* border: 2px solid red; */
  padding-left: 1rem;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 29px;
  color: #ffffff;
  padding-top: 2%;
  /* width: 15v	w; */
}

.readUnreadHead {
  /* border: 2px solid red; */
  padding-right: 1rem;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  padding-top: 2%;
  font-size: 11px;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;
}

.adminName {
  /* border: 2px solid red; */
  height: 70vh;
  overflow-y: scroll;
}
.singleAdmin {
  /* border:2px solid grey; */
  display: flex;
  height: auto;
  /* align-items: center; */
  border-bottom: 0.441176px solid rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.bellstyle {
  /* margin-top: 1rem; */
  border: none;
  background-color: #ffffff;
}
.bellIcon {
  /* border: 2px solid red; */
  background-color: white;
  color: #3b5998;
  font-weight: bold;
  font-size: 1.4rem;
  /* margin: 0.3rem; */
  cursor: pointer;
}

.profile {
  /* border: 2px solid red; */
  color: #3b5998;
  font-size: 2rem;
  padding-left: 4%;
  
}

.adminAndStatus {
  /* border: 2px solid red; */
  /* width: 15vw; */
  display: flex;
  flex-direction: column;
  padding: 0.6rem;
  
  /* margin-top: 0.8rem; */
}

.taskname {
  /* border: 2px solid red; */
  font-size: 0.8rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #212121;
  width: 16.5vw;
  justify-content: left;
  padding-bottom: 0.4rem;
}
.status {
  /* border: 2px solid red; */
  font-size: 0.7rem;
  /* width: fit-content; */
  display: flex;
  justify-content: space-between; 
  color: #888888;
  
}
.projectName{
  width: 7vw;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
.seeAll {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.2rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1d3557;
  cursor: pointer;
}

.unreadCount{
  border-radius: 100%;
  font-size: 0.5rem;
  color: white;
  background-color: #3b5998;
  width: 20px;
  height: 20px;
 padding: 1px;
  position: fixed;
  margin-left: 10px;
  text-align: center;
  padding-top: 0.3%;
  font-weight: 700;
}