.modalBody {
  /* border: 2px solid red; */
  height: 100vh;
  overflow-y: scroll; 
}
.modalBody2{
  height: 60vh;
  overflow-y: scroll;
}
.modalHeight{
  height: 100vh;
}
.detailsHead {
  /* font-family: "Inter"; */ 
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  padding-left: 1rem;
  padding-top: 1rem;
}
.cancel {
  
  border-radius: 8px;
  background: #FFFFFF;
  border: none;
  user-select: none;
  padding:8px 24px;
  left: 879px; 
  top: 770px;
  border: 1px solid rgba(173, 181, 189, 0.60);

  border-radius: 4px;
  color: #888888;
  /* border: 2px solid red; */
}
.modalBodyScrollable {
  /* overflow-y: scroll; */
  /* border: 2px solid red; */
}
.abc{
  height: 100vh;
}



.inputSection {

  /* border: 2px solid green; */
  
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}

.inputSection div {
  font-size: 14px;
  width: 15rem;
  /* width: fit-content; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #323232;
  /* border: 2px solid red; */
  margin-bottom: 2rem;
}

.inputSection input {
  width: 45rem;
  height: 37px;
  background: #fafafa;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  margin-right: 1rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
  padding-left: 2rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #888888;
  /* border: 2px solid red; */
  outline: none;
}

.addMembers {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: space-between;
  padding-right: 2%;
  color: #323232;
  /* border: 2px solid red; */
}
.input2 {
  margin-top: 1.2rem;
  width: 28.5rem;
  height: 37px;
  background: #fafafa;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding-left: 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #888888;
  /* border: 2px solid red; */
  outline: none;
}

.suggestionList {
  /* border: 2px solid red; */
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
  padding: 0.2rem;
  width: 28.5rem;
  overflow-y: scroll;
  position: absolute;
  height: auto;
  margin-top: -7%;
  margin-left: -1.5%;
  /* border: 2px solid red; */
}
.suggestionList2{
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
  padding: 0.2rem;
  width: 28.5rem;
  overflow-y: scroll;
  position: absolute;
  height: auto;
  margin-top: 0%;
  /* margin-left: -1.5%; */
}

.footer {
  display: flex;
  justify-content: flex-end;
}
.skip {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  color: #3b5998;
  cursor: pointer;
}

/* .addmembersDiv{
  
} */
.addmembers {
  background: #fafafa;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 0.5rem;
  margin-top: 2rem;
  width: 28.5rem;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 10rem;
  overflow-y: scroll;
  /* border: 2px solid red; */
}

.tagItem {
  background: #f0f0f0;
  border-radius: 500px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin: 0.125rem;
  display: flex;
  justify-content: space-between;
  width: fit-content;
}

.searchMember {
  /* border: 2px solid red; */
  flex-grow: 1;
  border: none;
  outline: none;
  padding-top: 0.5rem;
  /* width: 10rem; */
}


.createProjectModalHeader{
  display: flex;
  justify-content: space-between;
  width: 47.5rem;
}
.closeBtn{
  display: flex;
  position: relative;
  /* left:160%; */
}
.inputSection2{
  margin-top: 2rem;
  margin-left: 2%;
  margin-bottom: 1rem;
}
.adminDropDown{
  z-index: 99;
  position: absolute;
  margin-top: -2%;
  width: 46.5rem;
  display: flex;
  flex-direction: column;
padding: 1rem 0.75rem 0.75rem 0.75rem;
justify-content: center;
align-items: center;
border-radius: 0.5rem;
margin-left: 1.2%;
border: 1px solid #D3D3D3;
background: #FFF;

/* Shadow */
box-shadow: 0px 4px 12px 0px rgba(137, 137, 137, 0.25);
}
.adminElem{
  display: flex;
width: 44.5625rem;
padding-bottom: 0.5rem;
padding-top: 0.5rem;
cursor: pointer;
flex-direction: row;
align-items: center;
gap: 0.625rem;
border-bottom: 1px solid #EDEDED;

}
.adminName{
  color: #000;
  display: flex;
  align-items: center;
  width: 30vw;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 171.429% */
  letter-spacing: 0.03125rem;
}
.addTeamMember{
  color: #717789;
font-family: Inter;
font-size: 1.125rem;
font-style: normal;
font-weight: 600;
line-height: 1.5rem; /* 133.333% */
letter-spacing: 0.03125rem;
}
.createModalClose{
  border-radius: 0.5rem;
  border: none;
  background: none;
  display: flex;
height: 2.1875rem;
padding: 0.5rem 1rem;
justify-content: center;
align-items: center;
gap: 0.25rem;
}
.createModalSave{
  border-radius: 0.5rem;
  border: 1px solid #0084EF;
  background: #0084EF;
  display: flex;
height: 2.1875rem;
padding: 0.5rem 1rem;
justify-content: center;
align-items: center;
gap: 0.25rem;
}
.formName{
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 3%;
}
.teamMemText{
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 1%;
}
.firstName{
  display: flex;
width: 22.1875rem;
padding: 0.75rem 11.125rem 0.75rem 1rem;
align-items: center;
gap: 0.625rem;
border-radius: 0.5rem;
border: 0.75px solid #999;

}
.uploadAgreement{
  display: flex;
width: 46rem;
padding: 0.75rem 10rem 0.75rem 1rem;
align-items: center;
gap: 0.625rem;
}
.teamMemberCont{
  display: flex;
  height: 16rem;
  width: 46.5rem;
  flex-direction: column;
  margin-left: 1.5%;
  align-items: flex-start;
  gap: 0.75rem;
  flex-shrink: 0;
}
.teamMemberElem{
  display: flex;
  justify-content: space-between;
padding-bottom: 0px;
flex-direction: row;
align-items: center;
width: 46.5rem;
padding-bottom: 0.5rem;
padding-top: 0.5rem;
cursor: pointer;
gap: 0.625rem;
border-bottom: 1px solid #EDEDED;
}
.teamMemberElemLeft{
  display: flex;
  flex-direction: row;
}
.addBtn{
  display: flex;
padding: 0.625rem 1rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
border-radius: 0.5rem;
border: 1px solid #0084EF;
background: #FFF;
justify-content: flex-end;
}
.remBtn{
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  border: 1px solid #B40000;
  background: #FFF;
  justify-content: flex-end;
}