.errorHead {
  color: #999;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.errorSubHead {
  color: green;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.success {
  color: #b3261e;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.createBtn {
  color: #fff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid #0084ef;
  background: #0084ef;
  width: 100%;
  padding: 10px 14px;
  color: #ffffff;
}

.footerPara {
  color: #999;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  width: 100%;
  padding:24px;
}

.welcomeHead {
  padding: 24px;
  border-bottom: 1px solid #e0e3e6;
  color: #40454a;
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.allTextfield {
  padding: 24px 24px 28px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.error {
  font-size: 12px;
  color: #b3261e;
  padding-left: 0.5rem;
}

.passwordInputContainer {
  position: relative;
}

.showPasswordIcon {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.signInContainer {
  height: calc(100vh - 4rem);
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  display: flex;
  justify-content: center;
  padding: 2rem;
  overflow-y: scroll;
  /* border: 2px solid red; */
}

.boxContainer {
  width: 100%;
  max-width: 28rem;
  height: fit-content;
  border: 1px solid #e0e3e6;
  border-radius: 12px;
  background-color: #ffffff;
}

@media (min-height: 768px) {
  .signInContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-height: 768px) {
  .boxContainer {
    /* height: 35rem; */
    height: fit-content;
    overflow-y: scroll;
  }
}

@media (max-height: 768px) {
  .boxContainer {
    overflow-y: scroll;
    /* height: 35rem; */
  }
}

@media (max-height: 600px) {
  .signInContainer {
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    padding: 2rem;
  }
}

@media (max-width:767px){
  .boxContainer{
    margin-top: auto;
    margin-bottom: auto;
    position:fixed;
    width:90%;
  }
  .welcomeHead{
   padding:20px;
   font-size:24px;
   line-height:normal;
  }
  .allTextfield{
    padding:20px;
    gap:1.25rem;
  }
  .footerPara{
    font-size: 12px;
    line-height: 20px;
  }
.createBtn {
  font-size: 14px;
  padding: 14px 10px;
}
.errorHead{
  font-size:12px;
}
.success{
  font-size:10px;
}
.error{
  font-size:10px;
  padding: 0.5rem 0rem;
 }
}
.underlineInteraction:hover{
  color:rgba(0, 132, 239, 1);
}