.projectNameContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  cursor: default;
}

.offcanvas-body {
  overflow-x: hidden !important;
  overflow-y: scroll;
}
.offcanvas-body::-webkit-scrollbar {
  display: block !important;
  width: 6px;
  height: 10px;
  transition: all;
  transition-duration: 250ms;
}

/* Track */
.offcanvas-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.offcanvas-body::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/* Handle on hover */
.offcanvas-body::-webkit-scrollbar-thumb:hover {
  background: #d8d8d8;
}

.offcanvas-body::-webkit-scrollbar-thumb:focus {
  background: #bebebe;
}
.projectName {
  color: #212529;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  width: 70%;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.015rem;
}
.clientInviteCont {
  display: flex;
  justify-content: left;
  width: 100%;
}
.clientInvitebtn {
  display: flex;
  /* padding: 0.125rem 0.2rem; */
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  width: 90%;
  margin-right: -10%;
  cursor: pointer;
  border-radius: 0.125rem;
  border: 1px solid #0084ef;
}
.clientInviteSt {
  color: #0084ef;
  font-family: Inter;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 0.8125rem */
  letter-spacing: 0.0075rem;
}
.onGoingData {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}
.goingDateText {
  color: #0084ef;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.012rem;
}
.goingDateTextDays {
  color: #0084ef;
  font-family: Inter;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.0075rem;
}
.projectCreationContainerOver {
  display: flex;
  justify-content: space-between;
  /* padding-bottom: 1.75rem; */
  /* border-bottom: 1px solid #EDEDED; */
  width: 100%;
}
.projectCreationDetails {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.66rem;
  gap: 1.5rem;
}
.createdByContainer {
  display: flex;
  padding-right: 0px;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}
.createdByContainer1 {
  display: flex;
  padding-right: 0px;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  margin-top: -17%;
}
.teams_0 {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #9747ff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.teams_1 {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #f9a03f;
  color: white;
  margin-left: -20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.teams_2 {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #92d45e;
  color: white;
  margin-left: -40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.teams_3 {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #5b81f3;
  color: white;
  margin-left: -60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.createdByText {
  color: #666464;
  width: 100%;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.009rem;
}
.createdByName {
  color: #41424a;
  width: 100%;
  text-align: left;
  font-family: Inter;
  font-size: 0.675rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.0105rem;
  white-space: nowrap;
}

.contactDetailsContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  padding-bottom: 1.75rem;
  width: 100%;
  border-bottom: 1px solid #ededed;
}
.contactHeader {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
  letter-spacing: 0.03125rem;
}
.contactDetailsPhone {
  display: flex;
  align-items: center;
}
.phoneNumber {
  margin-left: 0.3rem;
  display: flex;
  flex-direction: column;
  color: #666464;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.009rem;
}
/* .contactDetails{
    display: flex;

} */
.detailText {
  color: #666464;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.009rem;
}
.detailData {
  color: #41424a;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.0105rem;
}
.contactDetailsEmail {
  display: flex;
  align-items: center;
}
.contactDetailsLocation {
  display: flex;
  align-items: center;
}
.attachmentContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1.75rem;
  padding-bottom: 0.75rem;
  width: 100%;
  border-bottom: 1px solid #ededed;
}
.attachmentHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
}
.attachmentText {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
  letter-spacing: 0.03125rem;
}
.addBtn {
  cursor: pointer;
  display: flex;
  padding: 0.1rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: 1px solid #0084ef;
}
.addText {
  color: #0084ef;
  font-family: Inter;
  font-size: 0.875rem;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
  letter-spacing: 0.03125rem;
}
.cross_img {
  border: none;
  border-radius: 50%;
  background: none;
  height: 30%;
  margin-left: -5%;
  z-index: 9999;
}
.attachmentItems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* border: 1px red solid; */
}
.image_cross {
  display: flex;
  flex-direction: row;
}
.attachmentName {
  color: #6b6363;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.009rem;
  margin-top: 2%;
  width: 5.375rem;
  overflow: hidden;
  height: 12%;
  text-overflow: ellipsis;
}
.notesContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
  gap: 1.1875rem;
  overflow-y: scroll;
  width: 96%;
}
.notesBody {
  display: flex;
  width: 27vw;
  padding: 0.5rem 17rem 0.5rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0.5rem;
  background: #f5f5f5;
}
.notesText {
  width: 25vw;
  color: #484545;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.03125rem;
}
.notesTime {
  color: #afacac;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 200% */
  letter-spacing: 0.03125rem;
}
.notesInput {
  display: flex;
  width: 176%;
  padding: 0.25rem 1rem 0.25rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.5rem;
  border: 0.5px solid #999;
}
.notesInputContainer {
  display: flex;
  width: 100%;
  margin-top: -6%;
  padding: 1rem 10rem 1rem 0rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0.5rem;
}
.notesbtn {
  display: flex;
  height: 2.1875rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.5rem;
  border: 1px solid #0084ef;
  background: #0084ef;
}
.notesBtnText {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
}
.submitBtn {
  display: flex;
  margin-top: -4%;
  width: 100%;
  justify-content: right;
}
.notesbtnCancel {
  border: none;
  background: none;
  margin-right: 4%;
}
.notesBtnCancelText {
  color: #c9c9c9;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
  letter-spacing: 0.03125rem;
}
.groupAttachment {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.uploadText {
  /* position:absolute;
    display: flex;
  margin-top: 1%;
  margin-left: 30%;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  cursor: pointer; */
  cursor: pointer;
  display: flex;
  padding: 0.1rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
}
.uploadText > input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  cursor: pointer;
  left: 0;
  z-index: 1;
  margin-top: 59%;
  margin-left: 100%;
  width: 10%;
}
.teamMemDropDown {
  overflow-x: hidden !important;
  /* box-shadow: 0px 2px 4px 0px #21212120; */
}
.teamMemDropDown::-webkit-scrollbar {
  display: block !important;
  /* width: 10%; */
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.createModalClose {
  border-radius: 0.5rem;
  border: none;
  background: none;
  display: flex;
  height: 2.1875rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.createModalSave {
  border-radius: 0.5rem;
  border: 1px solid #0084ef;
  background: #0084ef;
  display: flex;
  height: 2.1875rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.clientModalOk {
  border-radius: 0.5rem;
  border: 1px solid #0084ef;
  background: #0084ef;
  display: flex;
  height: 2.1875rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.createProjectModalHeader {
  display: flex;
  width: 34.5vw;
}
.closeBtn {
  display: flex;
  position: relative;
  left: 96%;
}
.revokeAccess {
  display: flex;
  cursor: pointer;
  justify-content: left;
  width: 100%;
  color: #d92d20;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.009rem;
  text-decoration-line: underline;
}
.addAdditionalInfo {
  display: flex;
  cursor: pointer;
  margin-bottom: -9%;
}

.addAdditionalInfoText {
  color: #0084ef;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 171.429% */
  letter-spacing: 0.03125rem;
}
.projectAdditionalDetail {
  display: flex;
  width: 100%;
  padding-right: 1rem;
  align-items: flex-start;
  gap: 2.6rem;
}
.revokeModalBody {
  color: var(--Neutral-Black, #09101d);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
}
.discardCloseBtn {
  display: flex;
  position: relative;
  /* left:60%; */
}
