.activeLeadCardHead {
  position: absolute;
  top: 0;
  left: 0;
  background: #0084ef;
  width: 100%;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
}
.createModalClose {
  border-radius: 0.5rem;
  border: none;
  background: none;
  display: flex;
  height: 2.1875rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.discardCloseBtn{
  display: flex;
  position: relative;
  /* left:60%; */
}
.createModalSave {
  border-radius: 0.5rem;
  border: 1px solid #0084ef;
  background: #0084ef;
  display: flex;
  height: 2.1875rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.requirementsCss{
  
}
.tooltip > div.tooltip-inner {
  background-color: black !important;
  color: white !important;
}
.lockBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lmsBox {
  /* border: 1px solid black; */
  margin-bottom: 10px;
  border-radius: 5px;
  border-radius: 7.476px;
  background: #fff;
  border: 1px solid #d9d7d7 !important;
  /* box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.10); */
}
.topHeader{
  border-top-left-radius: 7.476px;
  border-top-right-radius: 7.476px;
  border-bottom: 1px solid #d9d7d7 !important;

}
.addedOnText{
  color: #fff;
  font-family: Inter;
  font-size: 13.083px;
  font-style: normal;
  line-height: normal;
}
.topHeaderText {
  color: #fff;
  font-family: Inter;
  font-size: 13.083px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.tableData {
  /* width: 12%; */
  color: black;
  overflow: hidden;
  white-space: inherit;
  /* display: inline-block; */
}
/* .tableRow{
  display: table;
  padding: inherit;
} */

.tableRow td {
  /* text-align: center; */
  text-align: left;
  /* padding-left: inherit; */
  vertical-align: top;
}
.leadStatushover{
  width: 90%;
  margin-left: 5%;
}
.leadStatushover:hover{
border-radius: 5px !important;
width: 90%;
margin-left: 5%;
cursor: pointer;
}
.leadStatushovercontaineer{
  padding-top: 8px;
  padding-bottom: 8px;
}
.expectedClosure {
  width: 95px;
  color: dark;
  /* margin-left: 20px; */
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-device-width: 600px) and (max-device-width: 1024px) {
  .expectedClosure {
    width: 110px;
    font-size: 8px !important;
  }
  .ant-select-selection__placeholder {
    color: blue;
  }
}
