.main_cont {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
}
.modalWidth {
  width: 25.5% !important;
}
.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  width: max-content;
}
.tooltip > div.tooltip-inner {
  background-color: black !important;
  color: white !important;
}
.lockBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.createModalSave {
  border-radius: 0.5rem;
  border: 1px solid #0084ef;
  background: #0084ef;
  display: flex;
  height: 2.1875rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.selectStyle {
  width: 100%;
  height: 32px;
  outline-color: #d9d9d9 !important;
  border-radius: 5px;
}
.selectStyle:focus {
  outline-color: #4096ff !important;
}
/* .selectStyle:active{
  outline-color: none !important;
} */

.selectStyle:hover {
  border-color: #4096ff !important;
}
.createModalClose {
  border-radius: 0.5rem;
  border: none;
  background: none;
  display: flex;
  height: 2.1875rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.discardCloseBtn {
  display: flex;
  position: relative;
  /* left:60%; */
}

.firstFrameContainer {
  display: flex;
  color: #454545;
  position: relative;
}

.midContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 36px;
  padding-bottom: 28px;
  width: 80vw;
}

.firstContainer {
  height: fit-content;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin: 1rem 2rem;
  width: 92vw;
}

.secondContainer {
  margin: 1rem 2rem;
}

.addedByContainer {
  color: #003566;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 12px;
}

.tabs__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0px;
  margin-bottom: 1rem;
}
.columnText{
  font-weight: 500;
}
.sidePanel {
  width: 40%;
  z-index: -1;
}
.tab__selected {
  cursor: pointer;
  border-radius: 50px;
  border: 1px solid #0084ef;
  background: #fff;
  padding: 6px 16px;
  letter-spacing: 0.168px;
  display: flex;
  color: #0084ef;
  line-height: 16.94px;
  align-items: center;
  font-size: 14px;
}
.tab__not__selcted {
  letter-spacing: 0.168px;
  display: flex;
  cursor: pointer;
  border-radius: 50px;
  line-height: 16.94px;
  align-items: center;
  border: 1px solid #eeeeef;
  background: #fff;
  padding: 6px 16px;
  font-size: 14px;
}
.tbodyContainer {
  top: 13rem;
  position: fixed;
  width: 60%;
  overflow: scroll;
  z-index: -1;
}
.lmsContainer {
  left: 14.5%;
  width: 101.5%;
  display: flex;
  justify-content: space-between;
  /* overflow: scroll; */
  position: fixed;
  height: 75%;
  top: 10rem;
}
.ant-select > .ant-select-selector > .ant-select-selection-item {
  color: green !important;
}

.ant-select-selection {
  background-color: green;
}
.leadStatushover {
  width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: start;
  margin-right: 5%;
  padding-left: 0.5rem;
}
.leadStatushover:hover {
  border-radius: 5px !important;
  width: 90%;
  margin-left: 5%;
  display: flex;
  margin-right: 5%;
  padding-left: 0.5rem;
  cursor: pointer;
  justify-content: start;
}
.leadStatushovercontaineer {
  padding-top: 8px;
  padding-bottom: 8px;
}

.antd-selectt {
  color: red !important;
}
.ant-menu-item-selected {
  background-color: #b039cc !important;
}
@media screen and (min-device-width: 600px) and (max-device-width: 1024px) {
  .sidePanel {
    width: 35%;
  }
  .tbodyContainer {
    width: 54%;
  }
  .lmsContainer {
    width: 85%;
  }
}
