.accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 1rem;
    /* background-color: #f1f1f1; */
    /* border-bottom: 1px solid #ddd; */
    position: relative;
  }
  
  .accordion-arrow {
    transition: transform 0.3s;
  }
  
  .accordion-arrow.active {
    transform: rotate(180deg);
  }
  