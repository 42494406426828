/* Plus Icon Css Header */

.plusIconDiv {
  position: relative;
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.plusIconNew {
  display: flex;
  padding: 0.375rem 0.5rem;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: #3D3D3D;
}

.clickDropdown {
  position: absolute;
  top: 100%;
  display: inline-flex;
  padding: 0.375rem 0.25rem;
  flex-direction: column;
  align-items: flex-start;
  width: 11.3vw;
  border-radius: 0.5rem;
  background: #FFF;
  box-shadow: 0px 4px 12px 0px rgba(137, 137, 137, 0.25);
  z-index: 99;
}

.plusIconFirstChild {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
}

.plusIconFirstChild:hover,
.plusIconSecondChild:hover,
.momFirstChild:hover {
  cursor: pointer;
  border-radius: 0.5rem;
  background: #F5F5F5;
}

.plusIconSecondChildHover{
  width: 11.5vw;
}

.plusIconSecondChild {
  display: flex;
  width: 10.4875vw;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  /* border-radius: 0.5rem; */
  background: #FFF;
}

.plusIconThirdSubChild {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  position: relative;
  transition: opacity 0.3s ease, visibility 0.3s ease;

}

.plusIconSecondChildHover:hover .momDropdown {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  /* background-color: red; */
}

.momDropdown {
  display: flex;
  opacity: 0;
  visibility: hidden;
  /* transition: opacity 0.3s ease, visibility 0.3s ease; */
  pointer-events: none;
  padding: 0.375rem 0.25rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.5rem;
  background: #FFF;
  box-shadow: 0px 4px 12px 0px rgba(137, 137, 137, 0.25);
  position: absolute;
  /* position it absolutely */
  top: 63%;
  /* position it below the plusIconThirdSubChild */
  left: 100%;
  width: 100%;
  margin-left: 2%;
}

.textCss {
  color: #363535;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.009rem;
}

.userCirclePlus {
  width: 0.875rem;
  height: 0.875rem;
}

.momFirstChild {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  border-radius: 0.5rem;
  background: #FFF;
}