.modal {
    position: fixed;
    width: 100%;
    z-index:222222; 
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1rem;
    /* height:100%; */
   
  }
  
  .modalContent {
    /* background-color: #fff; 
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
    padding: 0.88rem 0.38rem 0.38rem 0.38rem;
    /* max-width: 26%; */
    border-radius: 0.75rem;
background: #FFF;
box-shadow: 4px 4px 16px 0px rgba(69, 69, 69, 0.20);
/* margin-bottom: 12%; */
height:20rem;
width:60rem;
  }
  .closeButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 1.5rem;
    color: #999; 
  }
  
  .closeButton:hover {
    color: #333; 
  }
  .dialogClass{
    display: flex;
    align-items: center;
    justify-content: center;
    height:92%;
  }
  .modalHeader{
    border-bottom: unset;
  }
  .videoIframe{
    position:relative;
  }
  .videoTitle{
   padding-left: 10px;
   color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.216px;
  }
  .videoCross{
    padding-right: 15px;
      cursor:pointer;
      color: #FFF;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.216px;
  margin-top: -5px;
    }
  .videoTop{
    padding-top: 10px;
    position: absolute;
    z-index:1;
    top:0;
    /* left:10px; */
    width:100%;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
    height:auto;
  }
 
  .contentClass{
    /* width:642px;
    height:360px; */
    /* width: 890px;
height: 520px; */
width:67vw;
height:80vh;
  }