.createProjectModalHeader {
  display: flex;
}
.formName {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* height: 12px; */
  /* margin-bottom: 3%; */
}
.buttonText {
  color: #828283;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.144px;
  overflow: scroll !important;
}
.commentInputBox {
  outline: none !important;
  border: none !important;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 83%;
  overflow: hidden;
  overflow: hidden;
  font-size: 14px;
}
.commentSendIcon {
  cursor: pointer;
  color: #0084ef;
}
.commentContainer {
  margin-bottom: auto;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  height: auto !important;;
  padding: 0.5rem;
}
.filterDropdownContainer{
  /* min-height: 400px; */
  max-height: 170px;
  overflow-y: scroll;
  width: 230px;
  word-break: break-all;

}
.dropdownButton {
  display: flex;
  align-items: center !important;
  height: 40px;
  padding: 8px 12px 7px 12px;
  justify-content: space-between;
  align-items: flex-end;
  width: 14rem !important;
  /* gap: 81px; */
  border-radius: 4px;
  border: 1px solid #8c8c8e;
  background: #fff;
}

.cancel {
  
  border-radius: 8px;
  background: #FFFFFF;
  border: none;
  user-select: none;
  padding:8px 24px;
  left: 879px; 
  top: 770px;
  border: 1px solid rgba(173, 181, 189, 0.60);

  border-radius: 4px;
  color: #888888;
  /* border: 2px solid red; */
}
.discardCloseBtn{
  display: flex;
  position: relative;
  /* left:60%; */
}
.closeBtn {
  display: flex;
  position: relative;

  /* left: 137%; */
}
.areaDescription{
  color: #454545;

font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 200% */
letter-spacing: 0.5px;
}
.areaUnit {
  color: #454545;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
}
.addLeadCancel {
  background-color: #ffffff;
  padding: 8px 24px;
  border-radius: 8px;
  border: none;
  color: #737373;
  font-size: 16px;
  font-weight: 500;
}
.addLeadTitle {
  font-weight: 500;
  font-size: 24px;
  color: #212529;
}
.addLeadHeaderButtons {
  display: flex;
  gap: 8px;
  align-items: center;
}
.addLeadOptions {
  display: inline-flex;
  /* padding: 6px 8px; */
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: #fff;
  /* box-shadow: 0px 4px 12px 0px rgba(137, 137, 137, 0.15); */
  margin-bottom: 24px;
}
.noSpinner::-webkit-inner-spin-button,
.noSpinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.noSpinner {
  -moz-appearance: textfield;
}
.addLeadOptionSelected {
  display: flex;
  padding: .375rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: .875rem;
  border-radius: 3.125rem;
  border: 0.5px solid #0084ef;
  background: #cce8ff;
  /* shadow */
  box-shadow: 0px 4px 12px 0px rgba(137, 137, 137, 0.25);
  cursor: pointer;
}
.addLeadOptionNotSelected {
  display: flex;
  padding: .375rem 1rem;
  justify-content: center;
  border: 1px solid #ededed;
  align-items: center;
  gap: 10px;
  font-size: .875rem;
  border-radius: 3.125rem;
  background: #fff;
  cursor: pointer;
}
.addLeadFieldsRow {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  width: 100%;
}
.mainwidthmodal{
  max-width: 800px;
}

.offcanvasBody {
  overflow-x: hidden !important;
  overflow-y: scroll;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  height: 63.6vh;
}
/* 
.offcanvasBody::-webkit-scrollbar {
  display: block !important;
  width: 6px;
  height: 10px;
  transition: all;
  transition-duration: 250ms;
}
.offcanvasBody::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.offcanvasBody::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}
.offcanvasBody::-webkit-scrollbar-thumb:hover {
  background: #d8d8d8;
}
.offcanvasBody::-webkit-scrollbar-thumb:focus {
  background: #bebebe;
} */

.addLeadFields {
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  height: 68.6vh;
  overflow-y: auto;
}
.addLeadFieldsTwo {
  width: 49%;
  margin: 1%;
}
.primryCon {
  color: #212529;
  font-size: 13px;
  font-weight: 500;
  /* margin-bottom: 16px;
  margin-top: 22px; */
}
@media (min-width: 576px) {
.modal{
  --mdb-modal-margin:unset !important;
}
}
.addduuucon {
  background-color: #fbe8fd;
  color: #850f8d;
  width: 136px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
  padding: 4px 6px 4px 6px;
  cursor: pointer;
  gap: 10px;
  border-radius: 4px 0px 0px 0px;
  font-size: 12px;
  font-weight: 400;
}
.addLeadFieldsOne {
  width: 99%;
  margin: 1%;
}

.createModalClose {
  border-radius: 0.5rem;
  border: none;
  background: none;
  display: flex;
  height: 2.1875rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.createModalSave {
  border-radius: 0.5rem;
  border: 1px solid #0084ef;
  background: #0084ef;
  display: flex;
  height: 2.1875rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

/*  css for phone mobile  */

.mobileimput{
  margin: "0px" !important;
}

.mobileimput>.MuiInputBase-root{
  background-color: white !important;
}

.phoneNumber>.special-label{
  background-color: red !important;
  height: 14px !important;
}