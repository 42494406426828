.tablekirow {
  height: 50px;
  border-bottom: 1.3px solid #e5e5e6;
}
.tablekirow:hover {
  height: 50px;
  border-left: 3px solid #0084ef;
  /* background: rgba(0, 132, 239, 0.05); */
  box-shadow: 0px 4px 12px 0px rgba(0, 132, 239, 0.05);
}
.tablekirow:hover #selectLead {
  visibility: visible;
}
#selectLead {
  visibility: hidden;
}
#selectLead:checked {
  visibility: visible;
}
.dropdownss{
  display: flex;
  align-items: center;
}
.toggyy2{
  overflow: scroll;
  max-height: 300px;
  width: 194px;
}
.tableKaPart {
  color: #191d23;
  font-size: 12px;
  font-weight: 400;
  /* cursor: pointer; */
}
.zero_State {
  margin-left: 38rem;
}
.tableKaPart .inner_checkbox {
  width: 50px;
  display: none;
}
.tablekirow:hover .inner_checkbox {
  display: block;
}
.maininput {
  border-radius: 8px;
  height: 39px;
  border: 1px solid #ededed;
  background: #fff;
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.inputs {
  border: none;
  width: 129px;
}
.mainname {
  color: #363535;
  font-size: 12px;
  font-weight: 500;
  width: 163px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mainnameGreen {
  color: #32aa71;
  font-size: 12px;
  font-weight: 500;
  width: 163px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.xlearrr {
  color: #6076ff;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.144px;
  text-decoration-line: underline;
}
/* .dropdown-menu {
    left: 412% !important;
    top: 128% !important;
    transform: translateX(-90%) !important;
  } */
.inputs:focus {
  outline: none;
}
.newest {
  width: 163px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.offcanvasBodyyy {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
}

.offcanvasBodyyy::-webkit-scrollbar {
  display: block !important;
  width: 6px;
  height: 10px;
  transition: all;
  transition-duration: 250ms;
}

/* Track */
.offcanvasBodyyy::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.offcanvasBodyyy::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/* Handle on hover */
.offcanvasBodyyy::-webkit-scrollbar-thumb:hover {
  background: #d8d8d8;
}

.offcanvasBodyyy::-webkit-scrollbar-thumb:focus {
  background: #bebebe;
}

.togyy:hover {
  --mdb-dropdown-state-background-color: unset !important;
}
.innerdiv {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  text-align: left;
}
.bluechipss {
  /* border-bottom: 0.5px solid #E7EAEE; */
  background: #e5f4ff;
  width: 85px;
  padding: 8px;
  height: 28px;
  color: #0084ef;
  font-size: 10px;
  height: 28px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.intersted {
  border-radius: 4px;
  background: #e5ffe5;
  display: flex;
  width: 85px;
  color: #008000;
  font-size: 10px;
  font-weight: 600;
  height: 28px;
  padding: 8px;
  justify-content: center;
  align-items: center;
}
.FollowUp {
  color: #0084ef;
  font-size: 10px;
  font-weight: 600;
  border-radius: 4px;
  height: 28px;
  background: #e5f4ff;
  display: flex;
  width: 85px;
  padding: 8px;
  justify-content: center;
  align-items: center;
}
.notintersted {
  color: #b40000;
  font-size: 10px;
  height: 28px;
  width: 85px;
  padding: 8px;
  font-weight: 500;
  border-radius: 4px;
  background: #fff0f0;
}
.nores {
  border-radius: 4px;
  background: #ebebeb;
  color: #454545;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  height: 28px;
  width: 85px;
  padding: 8px;
  justify-content: center;
  align-items: center;
}
.OnHold {
  color: #f78508;
  font-size: 10px;
  font-weight: 500;
  height: 28px;
  border-radius: 4px;
  background: #fef5eb;
  display: flex;
  width: 85px;
  padding: 8px;
  justify-content: center;
  align-items: center;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}
.remarkModalHeader{
  font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 19.36px;
letter-spacing: 0.012em;
text-align: left;
color: #2E2E2E;
}
.lastRemarkDate{
  color: #212121;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.lastRemarkStatus{
  display: flex;
width: 85px;
padding: 8px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
background: #E6FCFF;
color: #028391;
font-family: Inter;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.remarkData{
  padding:1%;
  border-radius: 8px;
border: 1px solid #DEDEDE;
background: #FAFAFA;
color: #212121;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 150% */
margin-top: 3%;
}

.offcanvasBodyyy2 {
  overflow-x: scroll !important;
    overflow-y: hidden !important;
}

.offcanvasBodyyy2::-webkit-scrollbar {
  display: block !important;
  width: 6px;
  height: 14px;
  transition: all;
  transition-duration: 250ms;
}

/* Track */
.offcanvasBodyyy2::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.offcanvasBodyyy2::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/* Handle on hover */
.offcanvasBodyyy2::-webkit-scrollbar-thumb:hover {
  background: #d8d8d8;
}

.offcanvasBodyyy2::-webkit-scrollbar-thumb:focus {
  background: #bebebe;
}