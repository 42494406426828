/* .modal-content{
    
        width: 607px !important;
        
       
} */

.react-quill{
        /* background-color: yellow; */
}

.react-quill >.ql-container{
        background: none !important;
}
.react-quill >.ql-container>.ql-editor{
        max-height: 90px;
        /* background-color: green; */
        overflow-y: scroll;
      
        border: none !important;
 }

 .react-quill >.ql-toolbar.ql-snow + .ql-container.ql-snow{
        height: 80px;
        border: 0px;
 }
 .react-quill>.ql-toolbar{
        padding: 0px !important;
        
        /* background-color: red; */

 }
 .react-quill>.ql-toolbar>.ql-formats{
       /* background-color: red; */
       margin-right: 0px !important ;
 }

/* .modal-dialog{
        

    height: 100vh !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
} */
.modal-90w {
        margin-top:7%;
        width: 40% !important;
        margin-top: 7%;
        max-width: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }