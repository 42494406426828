.togggle {
  border-radius: 8px;
  border: 0.5px solid #0084EF;
  background: #F0F9FF;
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  width: 153px;
}


.delay_css {
  border-radius: 4px 4px 0px 0px;
  background: #FCC;
  width: 66px;
  height: 31px;
  display: flex;
  padding: 8px 10px;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.chipContainer {
  display: flex;
  align-items: center;
  overflow-y:auto;
  align-items: center;
  padding-left: 0;
  /* overflow-x: none; */
  margin-bottom: 0;
  max-width: fit-content;
  /* max-width: 70%; */
  max-width: 99%;
    overflow-x: auto;
    margin-top: 14px !important;
    
}
.chipContainerEmail {
  display: flex;
  align-items: center;
  overflow-x:none;
  align-items: center;
  padding-left: 0;
  overflow-y: auto;
  margin-bottom: 0;
  max-width: fit-content;
  /* min-width: fit-content; */
}
.chipsEmail {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  color: #000000;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 25px;
  /* z-index: 12; */
  border: 1px solid black;
  background: #fff;
}
.chipContainer::-webkit-scrollbar {
  display: block !important;
  width: 6px;
  height: 10px;
  transition: all;
  transition-duration: 250ms;
}

/* Track */
.chipContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.chipContainer::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/* Handle on hover */
.chipContainer::-webkit-scrollbar-thumb:hover {
  background: #d8d8d8;
}
.innermile{
  color: #0084ef;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mainmile{
  display: flex;
  padding: 5px;
  align-items: center;
  margin-left: 0.4rem;
  gap: 4px;
  border-radius: 16px;
  background: #e5f4ff;
}
.icon-container:hover .icon {
  content: url('./images/SendAlertHover.svg');
}
.chipContainer::-webkit-scrollbar-thumb:focus {
  background: #bebebe;
}
.custom_quill .ql-toolbar {
  border-radius: 0 0 4px 4px !important;
  background-color: #ffffff !important;
  border-top: none !important;
  margin-bottom: 1rem;
  border: none !important;
  font-size: 12px;
}
.toggleenew{
  /* border: 1px solid #EDEDED !important;
  width: 766px !important; */
  /* display: flex;
  align-items: center;
  padding-left: 20px; */
}
.custom_quill .ql-container {
  border-radius: 4px 4px 0 0 !important;
  background-color: #ffffff !important;
  border-bottom: none !important;
  /* border-top: 1px solid rgb(217, 217, 217) !important; */
}
.custom_quill .ql-container > .ql-editor.ql-blank::before{
  font-style: normal;
  font-size: 12px;
}
.ql-container.ql-snow {
  border: none;
}
.customQuill2 .ql-container > .ql-editor.ql-blank::before{
  font-style: normal;
  font-size: 14px;

  color: #999;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
}
.attach:hover{
  color: rgb(27, 116, 234) !important;
 }
 .attachfile:hover{
   color: rgb(27, 116, 234) !important;
   /* background-color: red !important; */
   cursor: pointer;
 }
.cmn_delay {
  border-radius: 4px 4px 0px 0px;
  background: #FCC;
  width: 165px;
  display: flex;
  padding: 8px 10px;
  align-items: center;
  height: 31px;
  gap: 10px;
}

.progresss {
  color: #F78508;
  font-size: 12px;
  font-weight: 400;
}

.cmn_progress {
  border-radius: 4px 4px 0px 0px;
  background: #FDE7CE;
  width: 165px;
  display: flex;
  align-items: center;
  height: 31px;
  padding: 8px 10px;
  gap: 10px;
}

.cmplt {
  color: #32AA71;
  font-size: 12px;
  font-weight: 400;
}

.cmn_cmplt {
  border-radius: 4px 4px 0px 0px;
  background: #D8F3E7;
  display: flex;
  width: 165px;
  align-items: center;
  height: 31px;
  padding: 5px 9px;
  gap: 10px;
}

.completedd {
  border-radius: 50px;
  background: #EBF9F3;
  color: #32AA71;
  font-size: 12px;
  font-weight: 400;
  width: 79px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cmplt_css {
  border-radius: 4px 4px 0px 0px;
  background: #D8F3E7;
  display: flex;
  width: 92px;
  height: 31px;
  align-items: center;
  padding: 8px 10px;
  justify-content: center;
  gap: 10px;
}

.cmn_upcmng {
  border-radius: 4px 4px 0px 0px;
  background: #E6E6E6;
  width: 165px;
  align-items: center;
  height: 31px;
  display: flex;
  padding: 8px 10px;
  gap: 10px;
}

.upcmng_css {
  border-radius: 4px 4px 0px 0px;
  background: #E6E6E6;
  width: 92px;
  display: flex;
  align-items: center;
  height: 31px;
  padding: 8px 10px;
  justify-content: center;
  gap: 10px;
}

.task_grey {
  color: #454545;
  font-size: 10px;
  font-weight: 600;
}

.upcmng {
  color: #454545;
  font-size: 12px;
  font-weight: 400;
}

.borderBott {
  border-bottom: 1px solid rgb(237, 237, 237);
}

.task_orng {
  color: #F78508;
  font-size: 10px;
  font-weight: 600;
}

.progress_css {
  border-radius: 4px 4px 0px 0px;
  background: #FDE7CE;
  width: 99px;
  height: 31px;
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.delay {
  color: #B40000;
  font-size: 12px;
  font-weight: 400;
}

.task {
  color: #B40000;
  font-size: 10px;
  font-weight: 600;
}

.head_css {
  color: #454545;
  font-size: 11px;
  font-weight: 500;
  width: 10%;
  text-align: left;
}

.delay_internal {
  border-radius: 50px;
  background: #FFF0F0;
  color: #B40000;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 5px 9px;
}

.prog_internal {
  border-radius: 50px;
  background: #FEF5EB;
  color: #F78508;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 5px 9px;
}

.upcmng_internal {
  border-radius: 50px;
  background: #F2F2F2;
  color: #454545;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 5px 9px;
}

.toggleStatus {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  width: 77px;
}

.delayhead {
  font-size: 12px;
  font-weight: 400;
}

.dropdown-toggle.no-color-change {
  background-color: #F0F9FF !important;
  /* Set the background color as desired */
  color: #0084EF !important;
  /* Set the text color as desired */
}

.item_cmn {
  display: flex;
  gap: 12px;
}

.internal {
  color: #0084EF;
  font-size: 16px;
  font-weight: 400;
}

.cmn_internal {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.normal_Task {
  border-radius: 4px;
  background: #F0F9FF;
  display: flex;
  width: 153px;
  cursor: pointer;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.grey_Task {
  border-radius: 4px;
  cursor: pointer;
  background: #FCFCFC;
  display: flex;
  width: 153px;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.task_first_blue {
  color: #0084EF;
  font-size: 14px;
  font-weight: 500;
}

.no_circle {
  border-radius: 50px;
  background: #FFF;
  display: flex;
  padding: 1px 6px;
  justify-content: center;
  align-items: center;
  color: #666464;
  font-size: 10px;
  font-weight: 400;
}

.blue_circle {
  border-radius: 50px;
  background: #FFF;
  display: flex;
  padding: 1px 6px;
  justify-content: center;
  align-items: center;
  color: #0084EF;
  font-size: 10px;
  font-weight: 500;
}

.task_first {
  color: #666464;
  font-size: 14px;
  font-weight: 400;
}

.main_nav_css {
  padding: 10px 16px 0px 16px;
  border-right: 1px solid #EEEEEF;
  background: #FFF;
  height: 83vh;
  width: 13.7vw;
  padding-top: 3%;
  justify-content: center;
  align-items: center;
  position: fixed;
  /* -5 */
}

.padd_css {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.main_all_task {
  display: flex;
}
.frstttry{
  top: 13px;
  left: -115px;
  position:absolute;
  inset: 13px auto auto -115px !important;
}
.frstttry2{
  top: 13px;
  left: -115px;
  position:absolute;
  inset: 13px auto auto -165px !important;
}
.colored_field {
  /* border-radius: 50px; */
  border: 1px solid #0084EF;
  background: #FFF;
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #0084EF;
  font-size: 12px;
  font-weight: 400;
}
.ql-editor{
  height: 150px;
  border-radius: 4px;
  border: 1px solid rgb(204, 204, 204);
}
.grey_field {
  /* border-radius: 50px; */
  border: 1px solid #EEEEEF;
  background: #FFF;
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #1A1A1A;
  font-size: 12px;
  font-weight: 400;
}

.grey_field:hover {
  /* border-radius: 50px; */
  border: 1px solid #EEEEEF;
  background: #FFF;
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  color: #1A1A1A;
  align-items: center;
  display: flex;
  background-color: #F2F2F2;
  font-size: 12px;
  font-weight: 400;
}

.new_grey:hover {
  /* border-radius: 50px; */
  border: 1px solid #EEEEEF;
  background: #FFF;
  display: flex;
  padding: 6px 16px;
  color: #1A1A1A;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #F2F2F2;
  font-size: 12px;
  font-weight: 400;
}

.new_grey {
  /* border-radius: 50px; */
  border: 1px solid #EEEEEF;
  background: #FFF;
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #1A1A1A;
  font-size: 12px;
  font-weight: 400;
}

.major_div2 {
  display: flex;
  align-items: center;
  padding-right: 28px;
  gap: 9px;
  /* padding-top: 10px; */
}

.major_div {
  display: flex;
  /* padding-bottom: 20px ; */
  /* padding-left: 28px; */
  /* padding-top: 10px; */
  /* gap: 16px; */
}

.major_div_both {
  /* padding-top:43px ; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 71px; */
  margin-top: 39px;
  margin-bottom: 22px;
}

.created {
  color: #787878;
  font-size: 14px;
  font-weight: 300;
}

.major_div_bothhhhh {
  margin-left: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 71px; */
  margin-top: 35px;
  margin-bottom: 22px;
}

.togggle2 {
  border-radius: 8px;
  border: 0.5px solid #EDEDED;
  padding: 8px 10px;
  /* width: 98px; */
  background: #FFF;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.04);
}

.mytask {
  color: #0084EF;
  font-size: 12px;
  display: flex;
  font-weight: 500;
}

.cmn_mytask {
  gap: 10px;
  display: flex;
}

.showphase {
  color: #454545;
  font-size: 10px;
  font-weight: 400;
  height: 18px;
  width: 98px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.showphase:hover {
  height: 18px;
  width: 98px;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #F2F2F2;
}

.showcompp {
  color: #454545;
  font-size: 10px;
  font-weight: 400;
  height: 18px;
  gap: 5px;
  width: 103px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showcompp:hover {
  height: 18px;
  width: 103px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #F2F2F2;
}

.form-switch .form-check-input {
  cursor: pointer;
}

.time_name {
  color: #212121;
  font-size: 13px;
  font-weight: 400;
  max-width: 103px;
  text-align: left;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.importnew_btn_blue {
  border-radius: 8px;
  background: #0084EF;
  width: 106px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 18px;
  font-weight: 500;
}

.importnew_btn {
  border-radius: 8px;
  background: #DDD;
  width: 106px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8E8E8E;
  font-size: 18px;
  font-weight: 500;
}

.note1 {
  color: #212121;
  font-size: 18px;
  font-weight: 400;
}

.cancel_btn {
  color: #8E8E8E;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.cmn_end {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
  margin-top: 24px;
}

.success {
  color: #333;
  font-size: 16px;
  font-weight: 700;
}

.timeline_full {
  color: #333;
  font-size: 15px;
  font-weight: 400;
}

.wholeBorder {
  border-radius: 4px;
  border: 0.5px solid #999;
  display: flex;
  width: 166px;
  padding: 4px 4px 4px 8px;
  justify-content: space-between;
  align-items: center;
}

.addnote {
  color: #41424A;
  cursor: pointer;
  font-size: 12px;
  width: 124px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
}

.common_timeline {
  display: flex;
  align-items: center;
  /* padding-bottom: 4.2%; */
  gap: 16px;
}

.current_timelinee {
  border-radius: 4px 4px 0px 0px;
  background: #E5F4FF;
  padding: 8px 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  /* width: 295px; */
  height: 39px;
}

.share_btn {
  width: 48px;
  height: 28px;
  border-radius: 4px;
  background: #0084EF;
  color: #FFF;
  cursor: pointer;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time_head {
  display: flex;
  justify-content: space-between;
  margin-right: 5%;
  margin-top: 4.2%;
}

.all_over {
  border-radius: 8px;
  border: 1px solid #EDEDED;
  background: #FFF;
  margin-bottom: 1rem;
  padding: 13px;
  margin-right: 3%;
}

.singleChip {
  border-radius: 19px;
  background: #F2F2F2;
  height: 27px;
  display: flex;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.chipName {
  color: #333;
  font-size: 12px;
  font-weight: 400;
  text-wrap: nowrap;
}

.sendTo {
  color: #333;
  font-size: 20px;
  text-wrap: nowrap;
  font-weight: 400;
}

.chips_whole {
  /* width: 659px; */
  border-radius: 4px;
  display: flex;
  gap: 5px;
  padding: 14px;
  align-items: center;
  overflow: scroll;
  height: 48px;
}

.dropdown-item {
  font-size: 16px;
}

.not_app {
  color: #41424A;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.main_email {
  width: 767px;
  border-radius: 4px;
  border: 1px solid #EDEDED;
  padding: 13px;
  align-items: center;
  height: 257;
}

.send_btn {
  border-radius: 6px;
  background: #0084EF;
  width: 75px;
  height: 37px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
}

.form-outline .form-control.active ~ .form-label,
.form-outline .form-control:focus ~ .form-label,
.inpactive .form-control ~ .form-label {
  background-image: linear-gradient(180deg, #fff, #fff 10%, transparent 90%, transparent);
  padding-top: 0 !important;
  -webkit-transform: translateY(-0.7rem) translateY(0.1rem) scale(0.8) !important;
  transform: translateY(-0.7rem) translateY(0.1rem) scale(0.8) !important;
  z-index: 1;
}

.signinup .form-outline .form-control.active ~ .form-label,
.form-outline .form-control:focus ~ .form-label,
.inpactive .form-control ~ .form-label {
  background-image: linear-gradient(180deg, transparent, white 20%, transparent 90%, transparent);
  padding-top: 0 !important;
}
.input-custom {
  width: 710px;
  /* border-radius: 4px; */
  border: none;
  /* padding: 14px; */
  align-items: center;
}
.input-custom:focus {
  outline: none;
}

.share_head {
  color: #333;
  font-size: 24px;
  font-weight: 500;
}

.custom-input {
  border-radius: 4px;
  padding: 5px;
  height: 33px;
  padding: 8px 4px;
  margin-left: 6%;
  width: 185px;
  border: 0.5px solid #999;
}

.newInput {
  border-radius: 4px;
  padding: 5px;
  height: 31px;
  padding: 8px 4px;
  border: none;
  width: 163px;
}

.newInput:focus {
  outline: none;
}

.tasktext {
  overflow: hidden;
  color: #41424A;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.144px;
}

.sub_name {
  margin-left: 6%;
  width: 186px;
  border-radius: 4px;
  gap: 5px;
  display: flex;
  border: 0.5px solid #999;
}

.new_add {
  color: #454545;
  font-size: 10px;
  cursor: pointer;
  font-weight: 400;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #EDEDED;
  width: 80px;
  height: 28px;
}

.sharedOnn {
  border-radius: 8px;
  background: #FFF;
  padding: 4px 8px;
  color: #0084EF;
  font-size: 12px;
  font-weight: 400;
  text-wrap: nowrap;
}

.frst_time {
  color: #0084EF;
  font-size: 12px;
  font-weight: 500;
  text-wrap: nowrap;
}

.notShared {
  border-radius: 8px;
  border: 1px solid #EDEDED;
  width: 149px;
  height: 28px;
  color: #0084EF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
}

.timeline_border_new {
  border-radius: 16px;
  height: 27px;
  background: #E5F4FF;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0084EF;
  font-size: 12px;
  font-weight: 500;
  width: 74px;
}

.number {
  color: #454545;
  font-size: 10px;
  font-weight: 300;
}

.first_time {
  display: flex;
  align-items: center;
  gap: 10px;
}

.scnd_time {
  display: flex;
  align-items: center;
  gap: 16px;
}

.phaseName {
  color: #41424A;
  font-size: 12px;
  font-weight: 400;
}

.phase_main {
  gap: 8px;
  display: flex;
  height: 44px;
  align-items: end;
}

.okay {
  width: 100%;
  display: flex;
  height: 42px;
  width: 54px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  background: #0084EF;
  color: #FFF;
  font-size: 15px;
  font-weight: 600;
}

.Add_btn {
  width: 59px;
  height: 33px;
  cursor: pointer;
  border-radius: 4px;
  background: #0084EF;
  color: #FFF;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}

.import_taskk {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 59px;
  align-items: center;
  padding: 0px 16px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.new_time_name {
  color: #212121;
  font-size: 13px;
  font-weight: 400;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.basic_head {
  color: #787878;
  font-size: 11px;
  font-weight: 500;
}

.timeline {
  border-radius: 16px;
  background: #E5F4FF;
  padding: 6px 12px;
  color: #0084EF;
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 87px;
}

.most_common {
  display: flex;
  align-items: center;
  padding: 4.5px;
  background: white;
}

.left_header {
  display: flex;
  flex-direction: column;
  height: 66%;
  align-items: center;
  justify-content: start;
}

.clientLeft {
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  width: 11.2vw;
  top: 13.5rem;
}

.logs {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  color: #4A4A4A;
  justify-content: center;
  height: 61px;
  align-items: center;
  width: 200px;
  cursor: pointer;
  border-bottom: 1px solid #EDEDED;
}

.timeeee {
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
}
.btn-primary{
  --bs-btn-hover-bg: none;
}
.newDetail {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}
.approve {
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 61px;
  align-items: center;
  font-weight: 600;
  border-bottom: 2px solid #0084EF;
}

.sidebar_head {
  font-size: 20px;
  font-weight: 600;
  color: #212529;
  line-height: 24px;
  text-align: left;
}

.most_common_hov {
  height: 20px;
  border-radius: 4px;
  padding: 4px;
  background: #F2F2F2;
  display: flex;
  align-items: center;
}

.taskk_sub {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 7%;
}

.start_date {
  color: #41424A;
  font-size: 12px;
  font-weight: 400;
}

.start_text {
  margin-left: 6%;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #41424A;
  font-size: 12px;
  font-weight: 400;
}

.startBox {
  border-radius: 4px;
  border: 0.5px solid #999;
  width: 74px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #41424A;
  font-size: 12px;
  font-weight: 400;
}


.delayeddd {
  border-radius: 4px 4px 0px 0px;
  background: #FCC;
  width: 66px;
  height: 31px;
  color: #B40000;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.border_hover {
  border-left: 0.5px solid #D9D9D9;
  border-right: 0.5px solid #D9D9D9;
}

.border_other {
  border-left: 0.5px solid #D9D9D9;
}

.shared {
  border-radius: 16px;
  background: #E4F7EF;
  padding: 6px 8px;
  color: #32AA71;
  height: 27px;
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 400;
}

.table-containerrr {
  width: 100%;
  /* Adjust this width to control the width of the scrollable container */
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  display: flex;
  margin-bottom: 1.2rem;
  /* margin-left: 18px; */
}

/* .table-containerrr::-webkit-scrollbar {
    width: 10px;
    display: unset!important;
    cursor: pointer;
    height: 5px;
  }
  
  .table-containerrr::-webkit-scrollbar-track {
    border-radius: 10px;
  }
   
  .table-containerrr::-webkit-scrollbar-thumb {
    background: #8E8E8E; 
    border-radius: 10px;
  }
  
  .table-containerrr::-webkit-scrollbar-thumb:hover {
    background: #8E8E8E; 
  }  */
.timeline_border {
  display: flex;
  border-radius: 4px;
  border: 0.5px solid #EDEDED;
  background: #FFF;
  margin-bottom: 28px;
  width: 76.7vw;
  height: 44px;
  display: flex;
  align-items: center;
}

table {
  width: 100%;
  /* Ensure the table takes up the full width of its container */
}

.assgn {
  border-radius: 50px;
  background: #FF5D8F;
  width: 24px;
  height: 24px;
  color: #FFF;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}

.task_namee {
  width: 177px;
  overflow: hidden;
  color: #41424A;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
}

.tasktypecss {
  width: 120px;
  overflow: hidden;
  color: #41424A;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
}

.taskidcss {
  width: 109px;
  overflow: hidden;
  color: #41424A;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
}

.hover_task_name {
  color: #0084EF;
  width: 177px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
}

.phasename {
  overflow: hidden;
  color: #41424A;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
}

.main_phase {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 52px;
}

.custom-dropdown {
  right: 0;
  /* Force the Dropdown to open on the left */
  left: auto !important;
  /* Override Bootstrap's styles */
}

.comppp {
  color: #49B481;
  font-size: 10px;
  font-weight: 400;
  padding-left: 10px;
  height: 40px;
  display: flex;
  align-items: end;
}

.delayyy {
  color: #B40000;
  font-size: 10px;
  font-weight: 400;
  padding-left: 10px;
  height: 40px;
  display: flex;
  align-items: end;
}

.upcmnggg {
  color: #454545;
  font-size: 10px;
  font-weight: 400;
  padding-left: 10px;
  height: 30px;
  display: flex;
  align-items: end;
}

.inprogresss {
  color: #F78508;
  font-size: 10px;
  font-weight: 400;
  padding-left: 10px;
  height: 30px;
  display: flex;
  align-items: end;
}

.assgn_out {
  text-align: -webkit-center;
}

.pred_circle {
  border-radius: 50px;
  border: 1px solid #0084EF;
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  color: #0084EF;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.whole_pred {
  display: flex;
  align-items: center;
  gap: 8px;
}

.delayy {
  color: #B40000;
  font-size: 10px;
  font-weight: 400;
  height: 40px;
  display: flex;
  padding-left: 10px;
  align-items: end;
}

.inprogg {
  color: #F78508;
  font-size: 10px;
  padding-left: 10px;
  font-weight: 400;
  height: 30px;
  display: flex;
  align-items: end;
}

.upcomgg {
  color: #454545;
  font-size: 10px;
  font-weight: 400;
  height: 30px;
  padding-left: 10px;
  display: flex;
  align-items: end;
}

.hover_eye {
  background-color: #F2F2F2;
  border-radius: 3px;
  cursor: pointer;
  width: 24px;
  display: flex;
  height: 16px;
  align-items: center;
}

.yes2 {
  font-size: 12px;
  font-weight: 400;
  color: #41424A;
}

.days_no {
  /* height: 14px; */
  padding: 2px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  border: 1px solid #B40000;
  color: #B40000;
  font-size: 8px;
  font-weight: 600;
}

.rowcss {
  height: 56px;
  border-bottom: 0.5px solid #EDEDED;
}

.hoverrowcss {
  height: 56px;
  box-shadow: 0px 4px 4px 0px rgba(0, 132, 239, 0.20);
}

/* 0px 4px 4px -2px rgba(0, 132, 239, 0.20) */
.progress-box {
  width: 51px;
  outline: none;
  /* Remove default outline when focused */
  cursor: text;
  height: 28px;
  background: #DFF6EB;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #278B5B;
  font-size: 14px;
  border-radius: 8px;
  padding: 0 10px;
}

.update {
  color: #222338;
  font-size: 10px;
  font-weight: 500;
}

.task1 {
  color: #454545;
  font-size: 16px;
  font-weight: 500;
}

.t12_task {
  color: #454545;
  font-size: 12px;
  font-weight: 300;
}

.date_task {
  color: #454545;
  font-size: 12px;
  font-weight: 300;
}

.created_task {
  color: #454545;
  font-size: 12px;
  font-weight: 300;
}

.pres_taskk {
  display: flex;
  justify-content: space-between;
  height: 59px;
  align-items: center;
  padding: 0px 16px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.In-progress {
  border-radius: 50px;
  background: #FEF5EB;
  color: #F78508;
  font-size: 12px;
  font-weight: 400;
  padding: 6px 8px;
  width: 82px;
}

.text_pred {
  color: #212121;
  font-size: 12px;
  font-weight: 400;
}

.text_predName {
  max-width: 200px;
  overflow: auto;
  text-overflow: ellipsis;
  color: #212121;
  max-height: 47px;
  white-space: pre;
  font-size: 12px;
  font-weight: 400;
}

.pred_prog {
  border-radius: 4px;
  background: #EBFAF3;
  width: 43px;
  height: 28px;
  color: #717789;
  font-size: 10px;
  font-weight: 500;
  border-radius: 4px;
  background: #EBFAF3;
  width: 43px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 10px;
  color: #717789;
}

.timeline_header {
  display: flex;
  justify-content: space-between;
  width: 75vw;
  padding-right: 10px;
  padding-left: 23px;
}

.againtimeline_header {
  display: flex;
  gap: 20px;
  width: 75vw;
  padding-right: 10px;
  padding-left: 23px;
}

.add_task {

  display: flex;
  align-items: center;
  color: #0084EF;
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
  width: 65px;
}

.grey_add {
  background-color: #F2F2F2;
  color: #0084EF;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 300;
}

.no_days {
  color: #0084EF;
  font-size: 16px;
  margin-left: 8px;
  font-weight: 600;
}

.onGng {
  color: #212529;
  font-size: 10px;
  font-weight: 300;
  margin-left: 8px;
}

.project {
  color: #212529;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.24px;
}

.comn_frst_head {
  display: flex;
  justify-content: space-between;

}

.days {
  color: #0084EF;
  margin-left: 4px;
  font-size: 10px;
  font-weight: 300;
}

.projectproj {
  margin-top: 3px;
  color: #212529;
  font-size: 14px;
  font-weight: 400;
}

.viewfull {
  border-radius: 8px;
  background: #5B81F3;
  height: 32px;
  width: 147px;
  color: #FFF;
  justify-content: center;
  font-size: 13px;
  font-weight: 400;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.shareClient {
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #EDEDED;
  color: #5B81F3;
  font-size: 13px;
  text-wrap: nowrap;
  font-weight: 400;
  height: 30px;
  width: 198px;
  display: flex;
  align-items: center;
  justify-content: center;
}

table {
  width: 100%;
  /* Ensure the table takes up the full width of its container */
}

.assgn {
  border-radius: 50px;
  background: #FF5D8F;
  width: 24px;
  height: 24px;
  color: #FFF;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}

.task_namee {
  width: 177px;
  overflow: hidden;
  color: #41424A;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
}

.tasktypecss {
  width: 120px;
  overflow: hidden;
  color: #41424A;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
}

.taskidcss {
  width: 109px;
  overflow: hidden;
  color: #41424A;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
}

.hover_task_name {
  color: #0084EF;
  width: 177px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
}

.phasename {
  overflow: hidden;
  color: #41424A;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
}

.main_phase {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 52px;
}

.custom-dropdown {
  right: 0;
  /* Force the Dropdown to open on the left */
  left: auto !important;
  /* Override Bootstrap's styles */
}

.comppp {
  color: #49B481;
  font-size: 10px;
  font-weight: 400;
  padding-left: 10px;
  height: 30px;
  display: flex;
  align-items: end;
}

.assgn_out {
  text-align: -webkit-center;
}

.pred_circle {
  border-radius: 50px;
  border: 1px solid #0084EF;
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  color: #0084EF;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.whole_pred {
  display: flex;
  align-items: center;
  gap: 8px;
}

.delayy {
  color: #B40000;
  font-size: 10px;
  font-weight: 400;
  height: 40px;
  display: flex;
  padding-left: 10px;
  align-items: end;
}

.inprogg {
  color: #F78508;
  font-size: 10px;
  padding-left: 10px;
  font-weight: 400;
  height: 40px;
  display: flex;
  align-items: end;
}

.upcomgg {
  color: #454545;
  font-size: 10px;
  font-weight: 400;
  height: 40px;
  padding-left: 10px;
  display: flex;
  align-items: end;
}

.hover_eye {
  background-color: #F2F2F2;
  border-radius: 3px;
  cursor: pointer;
  width: 24px;
  display: flex;
  height: 16px;
  align-items: center;
}


.days_no {
  /* height: 14px; */
  padding: 2px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  border: 1px solid #B40000;
  color: #B40000;
  font-size: 8px;
  font-weight: 600;
}

.rowcss {
  height: 56px;
  border-bottom: 0.5px solid #EDEDED;
}

.hoverrowcss {
  height: 56px;
  box-shadow: 0px 4px 4px 0px rgba(0, 132, 239, 0.20);
}

/* 0px 4px 4px -2px rgba(0, 132, 239, 0.20) */
.progress-box {
  width: 51px;
  outline: none;
  /* Remove default outline when focused */
  cursor: text;
  height: 28px;
  background: #DFF6EB;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #278B5B;
  font-size: 14px;
  border-radius: 8px;
  padding: 0 10px;
}

.update {
  color: #222338;
  font-size: 10px;
  font-weight: 500;
}

.progress_main {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 7px;
}

.task1 {
  color: #454545;
  font-size: 16px;
  font-weight: 500;
}

.t12_task {
  color: #454545;
  font-size: 12px;
  font-weight: 300;
}

.date_task {
  color: #454545;
  font-size: 12px;
  font-weight: 300;
}

.created_task {
  color: #454545;
  font-size: 12px;
  font-weight: 300;
}

.pres_taskk {
  display: flex;
  justify-content: space-between;
  height: 59px;
  align-items: center;
  padding: 0px 16px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.In-progress {
  border-radius: 50px;
  background: #FEF5EB;
  color: #F78508;
  font-size: 12px;
  font-weight: 400;
  padding: 6px 8px;
  width: 82px;
}

.text_pred {
  color: #212121;
  font-size: 12px;
  font-weight: 400;
}

.text_predName {
  max-width: 200px;
  overflow: auto;
  text-overflow: ellipsis;
  color: #212121;
  max-height: 47px;
  white-space: pre;
  font-size: 12px;
  font-weight: 400;
}

.pred_prog {
  border-radius: 4px;
  background: #EBFAF3;
  width: 43px;
  height: 28px;
  color: #717789;
  font-size: 10px;
  font-weight: 500;
  border-radius: 4px;
  background: #EBFAF3;
  width: 43px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 10px;
  color: #717789;
}

.timeline_header {
  display: flex;
  justify-content: space-between;
  width: 75vw;
  padding-right: 10px;
  padding-left: 23px;
}

.add_task {

  display: flex;
  align-items: center;
  color: #0084EF;
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
  width: 65px;
}

.grey_add {
  background-color: #F2F2F2;
  color: #0084EF;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 300;
}

.no_days {
  color: #0084EF;
  font-size: 16px;
  margin-left: 8px;
  font-weight: 600;
}

.onGng {
  color: #212529;
  font-size: 10px;
  font-weight: 300;
  margin-left: 8px;
}

.project {
  color: #212529;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.24px;
}

.comn_frst_head {
  display: flex;
  justify-content: space-between;

}

.days {
  color: #0084EF;
  margin-left: 4px;
  font-size: 10px;
  font-weight: 300;
}

.projectproj {
  margin-top: 3px;
  color: #212529;
  font-size: 14px;
  font-weight: 400;
}

.viewfull {
  border-radius: 8px;
  background: #5B81F3;
  height: 32px;
  width: 147px;
  color: #FFF;
  justify-content: center;
  font-size: 13px;
  font-weight: 400;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.shareClient {
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #EDEDED;
  color: #5B81F3;
  font-size: 13px;
  text-wrap: nowrap;
  font-weight: 400;
  height: 30px;
  width: 198px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frst_head_div {
  color: #666464;
  font-size: 12px;
  font-weight: 400;
}

.secnd_head_div {
  color: #41424A;
  font-size: 14px;
  font-weight: 500;
}

.createdBy {
  height: 62px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.createdNote {
  display: flex;
  /* flex-direction: column; */
  margin-bottom: 19px;
  gap: 16px;
}

.add_btn {
  border-radius: 4px;
  border: 1px solid #0084EF;
  width: 76px;
  height: 26px;
  color: #0084EF;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  justify-content: center;
}

.notes_head {
  color: #4D4D4D;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.notes_info {
  color: #AFACAC;
  font-size: 12px;
  font-weight: 400;
  gap: 10px;
  display: flex;
}

.notes_text {
  color: #484545;
  font-size: 14px;
  font-weight: 400;
}

.notes_main_header {
  border-radius: 8px;
  background: #F5F5F5;
  gap: 10px;
  padding: 12px;
  margin-bottom: 13px;
  display: flex;
  flex-direction: column;
}

.already_sharedd {
  color: #14B814;
  font-size: 12px;
  font-weight: 400;
  border-radius: 16px;
  background: #E8FCE8;
  height: 25px;
  width: 217px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.abcd {
  position: relative;
  resize: horizontal;
  /* Enable horizontal resizing */
  overflow: auto;
  padding: 10px;
  border: 1px solid #ccc;
}

.abcd::before {
  content: "";
  position: absolute;
  top: 0;
  left: -5px;
  /* Adjust the handle position */
  width: 5px;
  height: 100%;
  cursor: ew-resize;
  /* Horizontal resize cursor */
}

span.Resizer.vertical {
  cursor: col-resize;
  width: 2.4px;
  background-color: #41424A;
}

.SplitPane {
  position: unset !important;
}

.Pane {
  overflow: hidden !important;
}

.timesheet_he {
  display: flex;
  align-items: center;
  width: 330px;
  margin-top: 11px;
  gap: 12px;
  margin-bottom: 11px;
}

.project_name {
  width: 104px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

._34SS0 {
  display: none !important;
}

._1nBOt {
  display: none !important;
}

.table {
  margin-left: unset !important;
}

.whole {
  width: 100%;
  display: flex;
  place-content: end;

}

.emailModalAddEmail:focus {
  outline: none;
}

.mailModalToDiv {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #DEDEDE;
}