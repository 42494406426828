.errorHead {
  color: #999;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.errorSubHead {
  color: #999;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.success {
  color: #b3261e;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.createBtn {
  color: #fff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid #0084ef;
  background: #0084ef;
  width: 100%;
  padding: 10px 14px;
  color: #ffffff;
}

.footerPara {
  color: #999;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  width: 100%;
  /* text-decoration-line: underline; */
}

.welcomeHead {
  padding: 24px;
  border-bottom: 1px solid #e0e3e6;
  color: #40454a;
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.allTextfield {
  padding: 24px 24px 28px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.error {
  font-size: 12px;
  color: #b3261e;
  padding-left: 0.5rem;
}

.forgotText {
  color: #0084ef;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: right;
  cursor: pointer;
  width: fit-content;
}

.passwordInputContainer {
  position: relative;
}

.showPasswordIcon {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.signInContainer {
  height: calc(100vh - 4rem);
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

@media (min-height: 600px) {
  .signInContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-height: 600px) {
  .signInContainer {
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    padding: 2rem;
  }
}

.mainContainer{
  width: 40%;
 height: fit-content;
  border: 1px solid #E0E3E6 ; 
  border-radius: 12px;
   background-color: #ffffff;
  }

@media (max-width:767px){
  .mainContainer{
    margin-top: auto;
    margin-bottom: auto;
    position:fixed;
  }
  .welcomeHead{
    font-size: 24px;
    line-height: normal;
    padding: 20px;
  }
 .allTextfield{
  padding: 20px;
  gap:1.25rem;
 }
  .footerPara{
 font-size: 12px;
 line-height: 20px;
  display:flex;
 flex-direction: column; 
 gap:8px;
 } 
 .createBtn{
  padding:14px 10px;
 }
 .forgotText{
  font-size: 12px;
  line-height: normal;
  margin-top: 12px;
 }
 .mainContainer{
  width:90%;
 }
 .flexCreateNewPassword{
  display: flex;
  flex-direction: column;
 }
 .error{
  font-size:10px;
  padding: 0.5rem 0rem;
 }
}
.underlineInteraction:hover{
  color:rgba(0, 132, 239, 1);
}