.container {
    display: flex;
    padding: 20px 34px 24px 20px;
    align-items: flex-start;
    border-radius: 12px;
    border: 1px solid #ededed;
    background: #fff;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
    margin-bottom: 5px;
    
  }
  
  .leftContainer {
    width: 10vw;
    color: #333;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    height:max-content;
    font-weight: 500;
    display: flex;
    align-items: flex-start;
    line-height: normal;
  }
  .rightContainer{
  width: 90%;
  }
  
  
  .subRightContainer{
    margin-bottom: 5px;
    /* border-bottom: 1px solid rgb(118, 117, 117); */
    padding-bottom: 5px;
  }
  .meetingContainer {
    /* display: flex; */
    /* width: 50%; */
    border-left: 0.75px solid #999;
    padding-left: 20px;
  }
  .meetingRemarksContainer {
    border-left: 0.75px solid #999;
    margin-top: 20px;
    padding-left: 20px;
  }
  .meetingRemarks {
    color: #8e8e8e;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }