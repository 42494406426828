.assignLeadBody {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  width: 100%;
}
.assignLeadApply {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.deleteModal{
  width: 436px;
}
.deleteHeader {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #101828;
  border: none;
}
.deleteBody {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #667085;
  border: none;
}
.deleteFooter {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 12px;
  border: none;
}
.deleteCancel {
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  /* opacity: 0px; */
  background: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #888888;
  border: 1px solid #ffffff;
}
.deleteDelete {
  background: #d92d20;
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
  border: 1px solid #d92d20;
}
