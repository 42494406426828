.container {
    height: 100vh;
    display: flex;
   
  }
  .first_contain {
    background-color: #e9effb;
    /* height: 100vh; */
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* border: 2px solid red; */
  }
  
  .ImgDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #e9effb;
  }
  
  .imgg {
    width: 95%;
  }
  
  .heading {
    color: #1D3557;
  font-family: "Inter";
  font-size: 1.55rem;
  font-style: normal;
  font-weight: 700;
  line-height: 127.523%; 
  /* margin-top: 2rem; */
  }
  
  .para {
    color: #212121;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1.25rem;
    /* margin-top: 0.5rem; */
    /* padding-right: 2rem; */
  }
  
  .icon {
    margin-top: 2rem;
    height: 1.5rem;
    width: 1.5rem;
  }
  
  /* second div */
  .second_contain {
    width: 50%;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
  }
  .PersonalContent_div {
    transform: scale(0.8);
    /* border: 2px solid red; */
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* padding-left: 5rem;
    padding-top: 2rem; */
    /* padding-top: 2rem; */
  }
  
  .personal_head {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 44px;
    color: #212121;
    /* border:2px solid red; */
  
  }
  
  .inputs {
    background: #f2f4fc;
    border-radius: 10px;
    border: none;
    padding: 0.8rem 1rem 0.8rem 1rem;
    width: 80%;
    margin-top: 1.5rem;
    color: #565353;
    font-size: 16px;
  }
  
  .inputsDrop {
    background: #f2f4fc;
    border-radius: 10px;
    border: none;
    padding: 0.4rem 1rem 0.4rem 0.4rem;
    width: 80%;
    margin-top: 1.5rem;
    font-size: 16px;
  }
  
  .inputsErr {
    background: #f2f4fc;
    border-radius: 10px;
    border: none;
    padding: 0.8rem 1rem 0.8rem 1rem;
    width: 80%;
    margin-top: 1.5rem;
    border: 2px solid rgb(190, 48, 48);
    font-size: 16px;
  }
  .inputsErrDrop {
    background: #f2f4fc;
    border-radius: 10px;
    border: none;
    padding: 0.4rem 1rem 0.4rem 0.4rem;
    width: 80%;
    margin-top: 1.5rem;
    border: 2px solid rgb(190, 48, 48);
    font-size: 16px;
  }
  .inputs::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #4d4d4d;
    font-size: 16px;
  }
  .btn {
    background: #2053c5;
    border-radius: 10px;
    border: none;
    padding: 0.9rem 1rem 0.9rem 1rem;
    width: 80%;
    margin-top: 2rem;
    color: #ffffff;
  }
  .backBtn {
    background: #f6f8fe;
    /* border-radius: 50px; */
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    color: #0084EF;
    border: none;
    border-radius: 14px;
    padding: 0.2rem 0.8rem 0.2rem 0.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .backBtn:hover{
    border: 1px solid #0084EF;
  }
  .backArrow {
    margin-right: 0.5rem;
  }
  
  .skip {
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: #B7B8BB;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  
  .nextBtn {
    width: 100%;
    padding: 15px 10px;
    color: #ffffff;
    background-color: #2053c5;
    border-radius: 12px;
    border: none;
    color: #fff;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;
  }
  .logoImg{
    height: 2rem;
    position: absolute;
    top: 2rem;
    left: 4rem;
    transform: scale(0.9);
  }
  .CarouselItem{
    box-shadow: none;
    border-bottom: none;
  }
  .miniImg{
    height: 2rem;
    width: 2rem;
   /* margin-top: 1.5rem;
    margin-bottom: 1rem; */
  }
  .insideCaraouselItem{
    display: flex;
    flex-direction: column;
    gap: 12px;
    }
.companyDetailsDiv{
   display: flex;
justify-content: space-between;
align-items: center;
width: 100%
}
.skipShowHide{
  display: none;
}
.cityContainer{
  display: flex;
   gap: 1rem;
    position: relative;
}
.skipBtnInLast{
  display:block;
}
.dotsContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9effb;
  padding-top:20px;
}
.activeDots{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: #2053C5;
}
.notActiveDots{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: #FFF;
}
.carouselMainContainer{
             display: flex;
              justify-content: center;
              align-items: center;
              width: 85%;
              margin:auto auto;
              transform:scale(0.85);
}
.name_for_anim {
  position: absolute;
  margin-top: 0.5rem;
  margin-left: 0.8rem;
  color: #999 !important;
  font-size: 15px !important;
  animation: anim1 0.4s forwards;
  display: none;
}
.name_notforanim_new {
  position: absolute;
  color: #252525 !important;
  font-size: 12px !important;
  margin-top: -0.5rem;
  margin-left: 0.8rem;
  background: white;
  animation: anim 0.4s forwards;
  padding: 0px 2px;
}

  @media (max-width:767px){
    .carouselMainContainer{
      transform: unset;
    }
    .imgg{
      margin-left: 10px;
    }
    .insideCaraouselItem{
      gap:8px;
    }
    .dotsContainer{
      /* padding-top:25px; */
      padding-bottom: 25px;
  }
  .activeDots{
      width: 10px;
      height: 10px;
  }
  .notActiveDots{
      width: 10px;
      height: 10px;
  }
    .container{
      flex-direction: column;
      overflow-y:scroll;
    }
    .first_contain{
      width:100%;
      flex-direction: column;
    }
    .logoImg{
      position:static;
      top: 0rem;
    left: 0rem; 
    width: 125.385px;
  height: 20px;
  transform: unset;
    }
    .logoImgContainer{
      background-color: white;
      width:100%;
      padding:13px 0px 15px 16px;
    }
    .flexForCarousel{
      display: flex;
      /* padding-bottom: 44px; */
      padding-top: 16px;
      background: #e9effb;
    }
    .miniImg{
      display: none;
    }
    
    .heading{
      font-size:14px;
    }
    .para{
      font-size: 10px;
      max-width:137px;
      margin-bottom: 0rem;
    }
    .second_contain{
      width:100%;
      justify-content: unset;
      padding-left: 16px; 
    padding-right:16px;
    overflow-y: unset;
   }
   .PersonalContent_div {
    transform: unset;
    width:100%;
    padding-top: 0rem;
  }
  .personal_head{
    font-size: 20px;
    line-height: normal;
  }
  .backBtn{
    display:none;
  }
  .skipShowHide{
    display: block;
  }
  .skip{
    font-size: 14px;
    line-height: 18px;
    font-weight:500;
    margin:0;
  }
  .companyDetailsDiv{
    margin-top:16px;
  }
  .cityContainer{
    /* flex-direction: column; */
  }
  .skipBtnInLast{
    display:none;
  }
  .nextBtn{
    border-radius:0;
    font-size: 16px;
    padding:12px;
  }
  }
  @media(min-width:320px) and (max-width:349px){
    .imgg{
      width: 153px;
    height: 89px;
    }
  }
  
  @media (min-width:350px) and (max-width:767px){
    .imgg{
      width: 163px;
    height: 99px;
    }
  }