.addStatusCancel {
  color: #454545;
  font-weight: 500;
  font-size: 14px;
  background-color: white;
  border: none;
  padding: 10px 14px;
}
.zeroSmall{
font-size: 16px;
padding-top: 21px;
font-weight: 400;
text-align: center;
}
.teamMember{
  width: 326px;
  border: none;
  height: 48px;
  outline: none;
  font-size: 14px;
  color: #8E8E8E;
}
.frstBox{
  display:flex;
  flex-direction:column;
  gap:16px;
  border-radius:8px;
    padding:8px;
}
.searchBox{
  width:324px;
    border:0.75px solid #EDEDED;
    border-radius:8px;
    padding:0px 10px;
    align-items: center;
    display:flex;
}
.chip{
  background-color: #F2F2F2;
  width:73px;
  height:15px;
  color: #8E8E8E;
  border-radius: 8px;
  align-items: center;
  display: flex;
  font-size: 10px;
  font-weight: 400;
  padding:4px 8px;
}
.zeroStateeText{
  font-family: Inter;
font-size: 14px;
font-style: italic;
font-weight: 400;
}
.zeroDiv{
  border:1px solid #999999;
  padding: 12px;
  height:400px;
  width:350px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainDivv{
  border:1px solid #999999;
  padding: 12px;
  height:400px;
  overflow: hidden;
  width:350px;
  border-radius: 8px;
}
.zeroHead{
font-size: 24px;
font-weight: 500;
text-align: center;

}
.crestee{
font-size: 28px;
font-weight: 600;
color: #212529;
}
.newWidth{
  max-width: 808px !important;
}
.borderteam{
  border:0.75px solid #999999;
  outline: none;
  margin-top: 16px;
}
.save{
  background: #DDDDDD;
width: 88px;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 8px;
color:  #8E8E8E;
font-size: 16px;
font-weight: 600;
}
.saveblue{
  background: #0084EF;
  width: 88px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color:  white;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
}
.addStatusSave {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  background-color: #0084ef;
  border: none;
  border-radius: 4px;
  padding: 10px 14px;
}
.addStatusStage {
  padding: 18px 16px;
  border: 1px solid #999999;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black !important;
  font-size: inherit;
}
.addStatusStages {
  padding: 12px;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #999999;
  width: 517px;
}
.addStatusStageOption {
  padding: 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}
.addStatusTag {
  padding: 12px 16px;
  border: 1px solid #999999;
  border-radius: 8px;
}
.addStatusHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dedede;
  padding: 14px 28px;
}

.CFTable {
  margin-top: 2.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  overflow-y: auto;
  max-height: 63%;
  position: relative;
}
.CFpaginationMain {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
.CFChangePages {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.CFPrevNext {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.CFPrevNext:disabled,
.CFPrevNext[disabled] {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-300, #d0d5dd);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.CFCurr {
  display: flex;
  width: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px;
  color: #0084ef;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  background: #e5f4ff;
}
.CFPageButton {
  display: flex;
  width: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px;
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: none;
  background-color: white;
}

.editFieldFieldNameInfo {
  color: #454545;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
}
.editFieldFieldNameDiv {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
